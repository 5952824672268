.brand-x4osAk9ulZ345RwXzpoDybYTAcUfeB0f .brand-content--login {
  background: url("../../../../assets/img/valio/valio_login_background.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  transition: none !important;

  @media (max-width: 991px) {
    background: url("../../../../assets/img/valio/valio_mobile_background.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 40px !important;
  }
}

.login.login--valio {
  width: 90%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.52);
  border-radius: 29px;
  padding: 50px 10px 60px;
  color: #002F6D;
  font-family: 'Gotham Medium';

  @media (min-width: 991px) {
    left: 25%;
    width: 460px;
    padding: 110px 0 120px;
  }

  img {
    width: 172px;
    max-width: 60%;
    margin: 0 auto 31px;
    display: block;
  }

  h5 {
    background: none;
    padding: 0 0 30px;
    margin: 0 auto;
    font-family: 'Gotham Bold';
    font-size: 38px !important;
    max-width: 90%;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 991px) {
      font-size: 32px !important;
    }

    span {
      display: block;
      font-family: 'Playfair';
      font-weight: 700;
      font-style: italic;
      font-size: 42px;
      text-transform: none;

      @media (max-width: 991px) {
        font-size: 36px !important;
      }
    }
  }

  p {
    width: 90%;
    max-width: 340px;
    font-size: 18px !important;
    margin: 0 auto 20px;
  }

  input[type=email],
  input[type=password] {
    background: none;
    border: none;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.82);
    color: #000;
    padding: 13px 15px 10px;
    font-size: 14px !important;
    font-family: 'Gotham Medium';

    &::placeholder {
      color: rgba(0,0,0,0.7) !important;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: rgba(0,0,0,0.7);
    }
  }

  input[type=submit] {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background-color: #002F6D;
    border-radius: 34px;
    color: #fff;
    font-size: 14px !important;
    text-transform: uppercase !important;
    font-family: 'Gotham Medium';
    border: none;
    margin: 20px auto 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #fff;
      background-color: #002F6D;
    }
  }

  .sso-link {
    padding: 0;
    margin-top: 40px;
    text-decoration: underline;
  }

  .forgot-link a {
    color: #002F6D;
    font-size: 14px;
    text-decoration: underline;
  }

  .form-error {
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 0px;
  }
}

.visible--extra {
  color: #002F6D;
}