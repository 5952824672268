.copy-link {
    display: inline-block;
    width: 32px;
    text-align: center;
    height: 32px;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 15px;
    left: calc(50% - 680px);

    @media (max-width: 1605px) {
        left: 15px;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 8px;
        width: 16px;
        transform: translateY(-50%);

        use,
        path {
            transition: all 0.3s ease-in-out;
        }

        &.icon-copied {
            width: 10px;
            left: 11px;
            display: none;

            use {
              fill: #fff;
            }
        }
    }

    img {
        position: absolute;
        top: 50%;
        width: 10px;
        left: 11px;
        transform: translateY(-50%);

        &.icon-copied {
            display: none;
        }
    }

    &:before {
        content: "Copy deep link";
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        width: 32px;
        border-radius: 32px;
        background: #eee;
        color: #eee;
        font-size: 12px;
        font-family: 'Rubik' !important;
        line-height: 32px;
        font-weight: normal;
        letter-spacing: normal;
        overflow: hidden;
        padding-left: 14px;
        text-align: center;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        svg {
            use {
                stroke: #fff;
            }

            path {
                fill: #fff;
            }
        }

        &:before {
            width: 134px;
            color: #fff;
            background: #0c56ff;
        }
    }

    &.copied {
        &:before {
            content: "";
            color: transparent !important;
            width: 32px;
        }

        svg {
            display: none;
        }

        img {
            &.icon-copied {
                display: none;
            }
        }
    }

    span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 32px;
        border-radius: 32px;
        cursor: pointer;

        &:hover {
            width: 134px;
        }
    }
}

@media (max-width: 1605px) {
  .menu--burger {
    //masthead
    .assume-as-first-copy-link .copy-link {
      top: 40px;
    }
    //modules loop detect first one
    .first-module .copy-link {
      top: 40px;
    }
  }
}

@media (max-width: 1320px) {
    //masthead
    .assume-as-first-copy-link .copy-link {
      top: 40px;
    }
    //modules loop detect first one
    .first-module .copy-link {
      top: 40px;
    }
}

@media (max-width: 992px) {
  .assume-as-first-copy-link .copy-link {
    top: 15px;
  }
  //modules loop detect first one
  .first-module .copy-link {
    top: 15px;
  }
}
