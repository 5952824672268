.multibrand-switcher {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 46;
  width: 55px;
  height: 100vh;
  border-right: 0px solid transparent;
  transition: all 0.2s ease-in-out;

  @media(max-width: 992px) {
    padding-top: 46px;
  }

  ul.multibrand-switcher__brands {
    list-style: none;
    margin: 14px 0 0 9px;
    padding: 0;

    li {
      margin: 0 0 10px;
      position: relative;

      .multibrand-switcher__logo {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        outline: solid;
        outline-color: transparent;
      }

      .multibrand-switcher__title {
        position: absolute;
        top: 2px;
        left: 53px;
        height: 32px;
        max-width: 200px;
        padding: 0 15px;
        border-radius: 32px;
        opacity: 0;
        font-size: 12px;
        font-family: Rubik;
        font-weight: 400;
        letter-spacing: normal;
        text-align: center;
        line-height: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        .multibrand-switcher__title {
          opacity: 1;
        }
      }
    }
  }
}