.module_downloads {
  position: relative;
  margin: 0 auto;
  max-width: 1100px;

  &:hover {
      .module_settings {
          display: block;
      }
  }

  .module_downloads__items {
      margin-top: 30px;
  }

  .module_downloads__item {
      background: #fff;
      height: 113px;
      margin-bottom: 20px;
      position: relative;

      a {
          display: block;
          cursor: pointer;
          padding: 0 54px;
          transition: all 0.2s ease-in-out;
      }

      h6 {
          color: #000;
          font-size: 22px;
          font-weight: 400;
          margin: 0;
          line-height: 113px;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: all 0.2s ease-in-out;

          a {
              color: #000;
          }
      }

      .actions {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 54px;

          img,
          svg {
            width: 18px;
            margin-left: 32px;
          }

          .spinner-icon.spinner-icon--20 {
            margin-top: 2px;
            pointer-events: none;
          }
      }

      @media(max-width: 992px) {
        h6 {
            max-width: 100%;
            width: 500px;
        }
      }

      @media(max-width: 768px) {
        h6 {
            width: 65vw;
        }
      }

      @media(max-width: 992px) {
          height: 80px;
          padding: 0 15px;

          a {
            padding: 0 54px 0 15px;
          }

          h6 {
              font-size: 14px;
              line-height: 80px;
              max-width: 100%;
          }

          .actions {
              right: 30px;
          }
      }

      &:hover {
        .file-info {
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
  }

  .module_downloads__file {
      border-radius: 5px;
      border: 1px dashed #b1b8c1;
      position: relative;
      text-align: center;
      height: 130px;
      transition: opacity 0.25s;

      &:hover {
          opacity: 0.8;
      }

      &.no-border {
          border: none;
      }

      .image {
          cursor: pointer;

          img {
              max-width: 100%;
          }
      }


      &.focus {
          border: 1px solid #0c56ff;
      }

      a {
          //@extend .transition;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          /*display: block;*/
          display: none;
          height: 35px;
          border-radius: 5px 5px 0 0;
          background-color: #0c56ff;
          line-height: 35px;
          padding: 0 21px;
          color: #fff;

          img {
              margin-left: 10px;
              margin-top: -2px;
          }
      }
  }
}

.module {
&.module--nopadding,
&.module--notop {
  .module_downloads {
    padding-top: 20px;
  }
}
}
