.audio-player {
    position: fixed;
    z-index: 5;
    bottom: -105px;
    left: 0;
    width: 100%;
    height: 80px;
    background: #1a1a1a;
    transition: bottom 0.55s;
    font-family: "Rubik";
    visibility: hidden;

    &.visible {
        bottom: 0;
    }

    &__wrapper {
        position: relative;
        width: 100%;
    }

    &__icon {
        float: left;
        width: 50px;
        height: 50px;
        margin: 15px 15px 0 30px;
        background: #626167;
        text-align: center;

        @media(max-width: 768px) {
            margin-left: 15px;
        }

        img {
            width: 20px;
            margin-top: 12px;
        }
    }

    &__data {
        float: left;
        @media(max-width: 992px) {
            display: none;
        }

        h6 {
            color: #fff;
            margin: 20px 0 5px;
            font-size: 16px;
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 220px;
            white-space: nowrap;
            min-height: 18px;
        }

        button {
            color: #626167;
            font-size: 14px;
            display: block;
            position: relative;
            width: 105px;
            transition: all 0.2s;
            border: none;
            background: none;
            padding: 0;
            cursor: pointer;
            text-align: left;

            span {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background: #626167;
                text-align: center;
                transition: all 0.2s;

                img {
                    width: 10px;
                    margin-top: -2px;
                }
            }

            &:hover {
              outline: none;
              color: #fff;

              span {
                background: #fff;
              }
            }

            &:focus, &:active {
              outline: none;
            }
        }
    }

    &__progress {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 500px;
        overflow: hidden;

        @media(max-width: 1200px) {
            width: 400px;
        }

        @media(max-width: 768px) {
            width: 200px;
        }

        @media(max-width: 400px) {
            width: 140px;
        }

        span {
            float: left;
            width: 45px;
            text-align: center;
            padding-top: 3px;
            color: #626167;
            font-size: 13px;
            font-weight: 400;

            &#seek-from {
                color: #fff;
                text-align: left;

                @media(max-width: 400px) {
                    display: none;
                }
            }

            &#seek-obj-container{
                width: 410px;
                padding-top: 0;
                position: relative;

                @media(max-width: 1200px) {
                    width: 310px;
                }

                @media(max-width: 768px) {
                    width: 110px;
                }

                @media(max-width: 400px) {
                    width: 140px;
                }

                span {
                    float: none;
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                    background-color: #0c56ff;
                    border: 2px solid #202020;
                }
            }

            &#seek-to {
                text-align: right;

                @media(max-width: 400px) {
                    display: none;
                }
            }
        }
    }

    &__buttons {
        float: right;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: #0455ff;
        text-align: center;
        margin: 15px 30px 0 0;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.85;
        }

        @media(max-width: 992px) {
            margin-right: 45px;
        }

        @media(max-width: 768px) {
            margin-right: 15px;
        }

        img {
            width: 20px;
            height: 20px;
            margin: 16px 0 0 2px;
        }

        &.button--play {
            img {
                margin-left: 4px;
            }
        }
    }

    &__volume {
        float: right;
        width: 130px;
        margin-top: 26px;
        margin-right: 45px;
        @media(min-width: 1200px) {
            margin-left: 30px;
        }
        @media(max-width: 992px) {
            display: none;
        }

        img {
            float: left;
            width: 21px;
            margin-top: 8px;
        }

        #volume-track {
            float: left;
            width: 100px;
            padding-top: 0;
            margin-left: 9px;
            position: relative;

            span {
                float: none;
                position: absolute;
                top: 10px;
                left: 0;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: #0c56ff;
                border: 2px solid #202020;
            }
        }
    }

    progress {
      width: 100%;
    }

    progress[value] {
      -webkit-appearance: none;
      appearance: none;
      background-color: white;
      color: #0455ff;
      height: 2px;
    }

    progress[value]::-webkit-progress-bar {
      background-color: #454449;
      border-radius: 2px;
      color: #0455ff;
    }

    progress::-webkit-progress-value {
      background-color: blue;
    }

    &__close {
        position: absolute;
        top: -22px;
        right: 0;
        background: #272e3c;
        color: #fff;
        font-size: 11px;
        padding: 3px 8px 2px;
        font-weight: bold;
        cursor: pointer;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.85;
        }
    }
}

$border:rgba(192,192,192,1);
.audiowrap{
    margin:5px 0;
    border:solid 1px $border;
    border-radius:3px;
    width:100%;
    overflow:auto;
    background-color:#efefef;
    > button{
       float:left;
    }
    .time{
        float:right;
        background-color:white;
        color:black;
        text-align:center;
        font-size:0.8em;
        padding:2px 5px;
    }
    .rangewrap{
        background-color:white;
        border-right:solid 1px $border;
        border-left:solid 1px $border;
        padding:0 6px;
        overflow:hidden;
        input{
            width:100%;
        }
    }
    .volwrap{
        background-color:white;
        border-right:solid 1px $border;
        float:right;
        padding:0 6px;
        width:100px;
        overflow:auto;
        .wrap2{
          overflow:hidden;
          input{
          }
        }
        img{
          float:left;
          height:19px;
        }
    }
}
