.login.login--valio {
  width: 90%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.52);
  border-radius: 29px;
  padding: 50px 10px 60px;
  color: #002F6D;
  font-family: 'Gotham Medium';

  @media (min-width: 991px) {
    left: 25%;
    width: 460px;
    padding: 110px 0 120px;
  }

  img {
    width: 172px;
    max-width: 60%;
    margin: 0 auto 31px;
    display: block;
  }

  h5 {
    background: none;
    padding: 0 0 30px;
    margin: 0 auto;
    font-family: 'Gotham Bold';
    font-size: 38px !important;
    width: 300px;
    max-width: 90%;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 900px) {
      font-size: 32px !important;
    }
  }

  p {
    width: 90%;
    max-width: 350px;
    font-size: 18px !important;
    margin: 0 auto 42px;
  }

  .two-buttons {
    text-align: center;

    input[type=submit] {
      margin: 0 10px;
      display: inline-block;
      width: calc(50% - 10px) !important;
      height: 42px;
      line-height: 42px;
      background-color: #002F6D;
      border-radius: 34px;
      color: #fff;
      font-size: 14px !important;
      text-transform: uppercase !important;
      font-family: 'Gotham Medium';

      &:hover {
        color: #fff;
        background-color: #002F6D;
      }

      &:nth-child(1) {
        margin-left: 0;
      }

      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }

  input[type=submit].cancel {
    width: 300px;
    color: #000;
    background-color: #eee;
    font-size: 14px !important;
    text-transform: uppercase !important;
    font-family: 'Gotham Medium';
    border-radius: 34px;

    &:hover {
      color: #000;
      background-color: #eee;
    }
  }

  .form-group--counter {
    position: relative;
    margin-bottom: 30px;

    span {
      position: absolute;
      right: 5px;
      bottom: 0px;
      color: #002F6D;
    }
  }

  input[type=email],
  textarea {
    background: none;
    border: none;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.82);
    color: #000;
    padding: 13px 15px 10px;
    font-size: 14px !important;
    font-family: 'Gotham Medium';
    resize: none;
    overflow: auto;

    &::placeholder {
      color: rgba(0,0,0,0.7) !important;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: rgba(0,0,0,0.7);
    }
  }

  .form-error {
    min-height: 30px;
    margin-top: -25px;
  }
}
