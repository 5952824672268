.card-tabs {
    ul {
        list-style: none;
        overflow: hidden;
        padding: 0;
        margin: 0;
        margin-left: -4px;
        width: calc(100% + 8px);

        li {
            float: left;
            width: calc(50% - 8px);
            margin: 0 4px;
            padding: 0 0 15px;
            position: relative;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 6px;
                border-radius: 2px;
                background: #ccc;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 6px;
                border-radius: 2px;
                background: #0c56ff;
                transition: width 0.075s linear;
            }
        }
    }

    &.layout--1 ul li {
      width: calc(100% - 8px);
    }

    &.layout--2 ul li {
      width: calc(50% - 8px);
    }

    &.layout--3 ul li {
      width: calc(33.33333% - 8px);
    }

    &.layout--4 ul li {
      width: calc(25% - 8px);
    }

    &.layout--5 ul li {
      width: calc(20% - 8px);
    }

    &.layout--6 ul li {
      width: calc(16.66666% - 8px);
    }

    &.layout--7 ul li {
      width: calc(14.28571% - 8px);
    }

    &.layout--8 ul li {
      width: calc(12.5% - 8px);
    }

    &.layout--9 ul li {
      width: calc(11.11111% - 8px);
    }

    &.layout--10 ul li {
      width: calc(10% - 8px);
    }

    &.layout--11 ul li {
      width: calc(9.09090% - 8px);
    }

    &.layout--12 ul li {
      width: calc(8.33333% - 8px);
    }

    &.layout--13 ul li {
      width: calc(7.69230% - 8px);
    }

    &.layout--14 ul li {
      width: calc(7.14285% - 8px);
    }

    &.layout--15 ul li {
      width: calc(6.66666% - 8px);
    }

    &.layout--16 ul li {
      width: calc(6.25% - 8px);
    }

    &.layout--17 ul li {
      width: calc(5.88235% - 8px);
    }

    &.layout--18 ul li {
      width: calc(5.55555% - 8px);
    }

    &.layout--19 ul li {
      width: calc(5.26315% - 8px);
    }

    &.layout--20 ul li {
      width: calc(5% - 8px);
    }
}

$animatemax: 100;

@for $i from 1 through $animatemax {
  .card-tabs ul li.animate-#{$i}:after {
    width: (1% * $i);
  }
}

.content-edit .card-tabs ul li:after,
.card-tabs ul li.no-animate:after,
.card-tabs ul li.animate-0:after,
.card-tabs ul li.animate-100:after,
.card-tabs ul li.active:after {
  transition: none;
}

.card-tabs ul li.animate-100:after,
.card-tabs ul li.active:after {
  width: 100%;
}

.card-content {
  .linebreak-true:nth-child(1) {
    margin-top: 20px !important;
  }
}

.tab-content {
  display: block;
  visibility: hidden;
  margin-right: -100%;
  width: 100%;

  &.visible {
    visibility: visible;
  }
}

div[cursor-follow-cards] {
  cursor: pointer;
}

.btn-switch-cards {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 10;
  left: -100px;
  bottom: -100px;
  background: #eee;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  svg {
    width: 60px;
    height: 78px;
    margin-left: 4px;
    transform: scaleX(-1);
    transition: transform 0.2s ease-in-out;
  }

  &.btn-switch-cards--right {
    svg {
      transform: scaleX(1);
      margin-left: 0;
      margin-right: 4px;
    }
  }
}
