.module--heading.module--notop {
    padding-top: 40px !important;
}

.module--heading.module--nobottom {
    padding-bottom: 15px !important;
}

.module--heading.module--nopadding {
    padding-top: 40px !important;
    padding-bottom: 15px !important;
}

.module_heading {
    position: relative;
    margin: 0 auto;
    max-width: 1100px;
}
