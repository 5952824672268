.cookie-consent {
  position: fixed;
  z-index: 10;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  max-width: 1920px;
  padding: 26px 65px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;

  &__text {
    position: absolute;
    top: 50%;
    left: 65px;
    transform: translateY(-50%);
    width: calc(100% - 345px);

    @media (max-width: 768px) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
    }

    p {
      margin: 0;
      color: #000;
      font-size: 16px;
      line-height: 1.4;
      letter-spacing: normal;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    float: right;
    width: 170px;
    overflow: hidden;

    @media (max-width: 768px) {
      float: none;
      margin: 30px auto 0;
    }

    button {
      display: block;
      width: 170px;
      height: 38px;
      text-align: center;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: normal;
      border-radius: 4px;
      cursor: pointer;

      &.reject {
        background-color: transparent;
        margin: 10px 0 0 0;        
      }

      &.accept {
        color: #fff;
        background-color: #0c56ff;
        border: 1px solid #0c56ff;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__close {
    position: absolute;
    content: " ";
    top: 12px;
    right: 12px;
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background-color: #0c56ff;
    cursor: pointer;
  }
}