.big-menu {
  .brand-menu {
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;
  }
}

.brand-menu.show-menu-scrollbar {
  @media(min-width: 1440px) {
    max-width: 240px;
  }
}

.brand-menu {
  bottom: 0;
  background-color: #fff;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 4;
  transform-style: preserve-3d;
  border-right: 1px solid #dfe5ed;

  .custom-scrollbar-menu {
      position: absolute;
      right: 0;
      top: 0;
      width: 6px;
      height: 100%;
      background: transparent;
      border-radius: 10px;
      opacity: 0;
      transition: all .2s ease;
      z-index: 11;
  }

  .custom-scroll-thumb {
      width: 100%;
      background: #888;
      border-radius: 10px;
      position: relative;
  }

  &.show-menu-scrollbar {
    .scroll-wrapper:hover {
      .custom-scrollbar-menu {
        opacity: 1;
      }
    }
  }

  &__padding {
    padding: 0 0 30px 0;
    white-space: pre-wrap;
  }

  @media (max-width: 992px) {
    z-index: 7;
    width: 100% !important;
    max-width: 100% !important;
    visibility: hidden;
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;
  }

  @media (min-width:992px) and (max-width: 1200px) {
    flex: 0 0 22% ;
    max-width: 22% ;
  }

  @media (min-width:992px) and (max-width: 1320px) {
    z-index: 2;
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;

    &.open {
      visibility: visible;
      transform: translateX(0%);
    }
  }

  &.open {
    visibility: visible;
    transform: translateX(0%) !important;
  }

  @media(min-width: 1440px) {
    max-width: 240px;
  }

  .scroll-wrapper {
    height: 100%;

    .scroll-inner {
      width: 100%;
      overflow-y: scroll;
      box-sizing: content-box;
      height: 100%;
      /* Hide scrollbar for Firefox */
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        width: 0px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
        border-radius: 10px;
      }
    }
  }

  .brand-menu-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: -1px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    &.multibrand {
      padding-left: 56px;
    }

    @media(min-width:992px) {
      &.audio-playing {
        padding: 0 0 110px 0;
      }
    }

    &:hover {
      .scroll-inner {
        &::-webkit-scrollbar-thumb {
          display: block;
        }
      }
    }
  }

  .menu-bar-grabber {
    position: relative;
    margin-top: 35px;
    min-height: 5px;
    width: 100%;
  }

  .brand-data {
     padding: 0 0 0;

    .resizable-logo {
      display: inline-block;
      position: relative;
      outline-offset: -1px;
      max-width: 100%;
    }

    .logo-box {
      padding-top: 40px;
    }

    .title-box {
      position: relative;
    }

    h6 {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.3;
      margin: 0 0 10px;
      margin-top: 30px;
      word-break: break-word;
      width: 169px;
      left: 35px;
      position: relative;
    }

    img {
      width: 100%;
      max-width: 100%;
      display: inline-block;
    }
  }

  .brand-block {
    position: relative;

    svg.ico-page-locked {
      width: 9px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 11px;
      fill-rule: evenodd;
      clip-rule: evenodd;
    }

    svg.ico-menu-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 4px;
      left: 13px;
    }

    &__section {
      color: #b1b8c1;
      margin: 0;
      position: relative;
      padding: 10px 40px 10px 27px;
      cursor: pointer;
      margin-left: -10px;

      a {
        &:hover {
          text-decoration: none;
          font-weight: bold;
        }
      }
    }

    &__sub {
      margin: 0 0 0 13px;
      padding: 0 0 5px !important;

      .brand-block__section {
        padding: 4px 40px 4px 27px;
      }

      .brand-block__sub__item {
        position: relative;
        padding: 5px 15px 5px 30px;

        .ico-menu-arrow {
          top: 6px;
          left: 11px
        }

        .brand-block__section {
          padding: 0;
          cursor: pointer;
        }

        a {
          color: #2f3844;
          font-weight: 400;

          &:hover {
            text-decoration: none;
            font-weight: bold;
          }

          &.active {
            color: #0c56ff;
            font-weight: 700;
          }

          &.inactive {
            color: #75787b;
            font-weight: 300;
            cursor: default;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &.collapsed {
      svg.ico-menu-arrow {
        transform: translateY(-50%) rotate(-90deg);
        left: 12px;
      }

       .brand-block__sub {
        display: none;
      }
    }
  }
}

.brand-menu.hide-menu-scrollbar {
  .scroll-wrapper {
    .scroll-inner {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

body.menu--hidden {
  .brand-menu {
    transform: translateX(-101%);
    transition: transform 0.3s ease-in-out;
  }
}


.container-full {
  transition: margin 0.55s ease-in-out;
}

.container-full.audio-playing {
  padding-bottom: 80px;
}
