.locked-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0,0,0,0.6);
  transition: all 0.25s;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.locked-content {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 12;
  transform: translate(-50%,-50%);
  width: 773px;
  height: 498px;
  border-radius: 4px;
  background: url(../../../assets/img/locked-bg.png) no-repeat right top #000;
  transition: all 0.25s;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  div {
    width: 420px;
    padding: 128px 0 0 78px;

    h4 {
      color: #fff;
      font-family: 'Rubik';
      font-size: 26px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 36px;
      margin: 0 0 20px;
      width: 340px;
    }

    p {
      color: #919191;
      font-family: "brandon-grotesque";
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      margin: 0 0 45px;
    }

    button {
      width: 117px;
      height: 45px;
      border-radius: 4px !important;
      text-align: center;
      color: #fff;
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: 300;
      border: 1px solid #bababc;
      background: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
    }
  }
}
