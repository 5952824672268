.module_content {
	position: relative;
	margin: 0 auto;
	max-width: 1070px;
	display: -webkit-box;
	display: flex;

	.row {
		width: calc(100%);
		margin-left: 0;
		margin-right: 0;
	}

	.empty {
		height: 1px;
	}

	@media (max-width: 992px) {
		.dynamic-content {
			width: 100% !important;
		}

		.module_content {
			max-width: 100vw;
		}
	}

	@media (max-width: 1100px) {
		.row {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&.full--1 {
		@media (max-width: 1100px) {
			.row {
				padding-left: unset;
				padding-right: unset;
			}
		}
	}

	.module_content_column {
		float: left;
		width: 100%;
		height: 100%;
		padding: 0 15px;
		position: relative;
		min-height: 1px;

		.module_content_include {
			height: 100%;

			.module_paragraph_column {
				height: 100%;
			}
		}

		.image {
			img {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	@media (min-width: 769px) {
		.module_content_column {
			background-clip: content-box !important;
		}
	}

	&.column-padding-0 {

		.module_content_column {
			padding-left: 0;
			padding-right: 0;
		}

		@media (max-width: 992px) {
			padding-left: 0;
			padding-right: 0;

			.module_content_column {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}

		.delete {
			right: 0;
		}

		.dynamic-content {
			border: none !important;
		}
	}

	&.module_content--one {
		&.column-padding-0 {
			.module_content_column {
				padding-left: 0;
				padding-right: 0;
			}

			.delete {
				right: 0;
			}
		}

		&.column-padding-1 {

			.module_content_column {
				padding-left: 0;
				padding-right: 0;
			}

			.delete {
				right: 0;
			}
		}
	}

	&.module_content--one.full--1, &.module_content.full--1 {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;

		.row {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}

		.module_image,
		.module_video {
			max-width: 100% !important;

			img,
			video {
				width: 100%;
			}
		}

		.dynamic-content {
			border: none !important;
		}
	}

	&.module_content--two {
		.module_content_column {
			width: 50%;

			@media(max-width: 768px) {
				width: 100%;
				padding-bottom: 20px;
			}
		}

		&.grid-1_2 {
			.col_1 {
				width: 33.3333%;
			}

			.col_2 {
				width: 66.6666%;
			}
		}

		&.grid-2_1 {
			.col_1 {
				width: 66.6666%;
			}

			.col_2 {
				width: 33.3333%;
			}
		}
	}

	&.module_content--three {
		.module_content_column {
			width: 33.3333%;

			@media(max-width: 768px) {
				width: 100%;
				padding-bottom: 20px;
			}
		}

		&.grid-1_2_1 {
			.col_1 {
				width: 25%;
			}

			.col_2 {
				width: 50%;
			}

			.col_3 {
				width: 25%;
			}
		}

		&.grid-1_1_2 {
			.col_1 {
				width: 25%;
			}

			.col_2 {
				width: 25%;
			}

			.col_3 {
				width: 50%;
			}
		}

		&.grid-2_1_1 {
			.col_1 {
				width: 50%;
			}

			.col_2 {
				width: 25%;
			}

			.col_3 {
				width: 25%;
			}
		}
	}

	&.module_content--four {
		.module_content_column {
			width: 25%;

			@media(max-width: 768px) {
				width: 100%;
				padding-bottom: 20px;
			}
		}
	}

	.clearfix {
		clear: both;
	}

	.module_image {
		padding: 0;
		height: 100%;

		@media (max-width: 992px) {
			width: 100% !important;

			.text {
				width: 100% !important;
			}
		}

		&.module_image--empty {
			.module_image__image {
				min-height: 220px;
			}
		}

		.module_image__image {
			.choose-file {
				top: 50%;
				transform: translateY(-50%);
			}

			.buttons {
				ul {
					margin: 0;
					width: 224px;
				}
			}

			.actions {
				position: absolute;
				transform: translateY(-50%);
				right: 32px;
				bottom: 5px;

				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						display: inline-block;
						margin-left: 15px;
						background: #eee;
						width: 32px;
						border-radius: 32px;
						text-align: center;
						height: 32px;
						line-height: 30px;
						cursor: pointer;

						img {
							width: 14px;
							cursor: pointer !important;
							transition: opacity 0.2s ease-in;
						}

						&:hover {
							img {
								opacity: 0.7;
							}
						}
					}
				}
			}
		}

		.with--hover {
			position: relative;
			overflow: hidden;

			.hover-default {
				display: block;
			}

			.hover-hover {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: opacity 0.2s ease-in;

				img,
				video {
					width: 100%;
					display: block;
				}
			}

			@media(min-width: 768px) {
				&:hover {
					.hover-hover {
						opacity: 1;
					}
				}
			}
		}
	}

	.module_audio {
		.module_audio__audio {
			float: none;
			width: 100%;
		}
	}

	.module_video--empty {
		.video {
			min-height: 220px;
		}
	}
}

/* Mobile horizontal content scroll */
@media (max-width: 768px) {
	.module_content.mobile-layout-horizontal {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		padding-top: 5px !important; /* Avoid strange text cut */

		/* Hide scrollbar for all browsers */
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */

		.row {
			display: inline-flex;
			flex-wrap: nowrap;

			.module_content_column {
				float: none;
				min-width: 75%;
  			box-sizing: border-box;
			}
		}
	}

	.module_content::-webkit-scrollbar {
		display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
	}
}

.module_video {
	.image {
		background: url('../../../assets/img/20207673548.gif') no-repeat;
		background-size: 300px;
		background-position: center;

		@media (max-width: 992px) {
			width: 100% !important;

			.text {
				width: 100% !important;
			}
		}

		.actions {
			position: absolute;
			transform: translateY(-50%);
			right: 32px;
			bottom: 5px;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					margin-left: 15px;
					background: #eee;
					width: 32px;
					border-radius: 32px;
					text-align: center;
					height: 32px;
					line-height: 30px;
					cursor: pointer;

					img {
						width: 14px !important;
						cursor: pointer !important;
						transition: all 0.25s;
					}

					&:hover {
						img {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}

	video {
		display: block;
		width: 100%;
		max-width: 100%;
		min-height: 200px;
	}

	.with--hover {
		position: relative;
		overflow: hidden;
		background: none !important;

		.hover-default {
			display: block;
			transition: opacity 0.2s ease-in;
		}

		.hover-hover {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: opacity 0.2s ease-in;

			img,
			video {
				width: 100%;
				display: block;
			}
		}

		@media(min-width: 768px) {
			&:hover {
				.hover-default {
					opacity: 0.01;
				}

				.hover-hover {
					opacity: 1;
				}
			}
		}

		&:hover {
			background: none !important;
		}
	}
}

.col_1,
.col_2,
.col_3 {
	@media(max-width: 768px) {
		width: 100% !important;
		padding-bottom: 20px !important;
	}
}

.module_content {
	.module_content_column:last-child {
		@media (max-width: 768px) {
			padding-bottom: 0px !important;
		}
	}
}

$circle-size: 82px;
$circle-background: #ddd;
$circle-color: #0c56ff;
$inset-size: 80px;
$inset-color: #fff;
$transition-length: 0.25s;

.ko-progress-circle {
	width: $circle-size;
	height: $circle-size;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: $circle-background;
	border-radius: 50%;

	.ko-progress-circle__slice,
	.ko-progress-circle__fill {
		width: $circle-size;
		height: $circle-size;
		position: absolute;
		-webkit-backface-visibility: hidden;
		transition: transform $transition-length;
		border-radius: 50%;
	}

	.ko-progress-circle__slice {
		clip: rect(0px, $circle-size, $circle-size, $circle-size/2);

		.ko-progress-circle__fill {
			clip: rect(0px, $circle-size/2, $circle-size, 0px);
			background-color: $circle-color;
		}
	}

	.ko-progress-circle__overlay {
		width: $inset-size;
		height: $inset-size;
		position: absolute;
		margin-left: ($circle-size - $inset-size)/2;
		margin-top: ($circle-size - $inset-size)/2;

		background-color: $inset-color;
		border-radius: 50%;
	}

	$i: 0;
	$increment: 180deg / 100;

	@while $i <=100 {
		&[data-progress='#{$i}'] {

			.ko-progress-circle__slice.full,
			.ko-progress-circle__fill {
				transform: rotate($increment * $i);
			}

			.ko-progress-circle__fill.ko-progress-circle__bar {
				transform: rotate($increment * $i * 2);
			}

			$i: $i + 1;
		}
	}
}

.content-grid-module.parallax {
	background-attachment: fixed;
}

.module-height-fold {
	min-height: 100vh;
	height: 100vh;
	box-sizing: content-box;

	.module_content {
		height: 100%;
	}
}

.module-height-half {
	min-height: 50vh;
	height: 420px;
	box-sizing: content-box;
	.module_content {
		height: 100%;
	}
}

.module-height-small {
	min-height: 210px;
	height: 210px;
	box-sizing: content-box;
	.module_content {
		height: 100%;
	}
}

.content-grid-module-master-header {
	overflow: hidden;

	.module_content {
		height: 100%;
	}

	h1 {
    font-size: 48px;

		@media(max-width: 992px) {
      font-size: 36px;
    }
  }

  h2 {
    font-size: 28px;

    @media(max-width: 992px) {
      font-size: 22px;
    }
  }

  p {
    font-size: 18px;

		@media(max-width: 992px) {
      font-size: 16px;
    }
  }
}

.content-grid-module-master-header-legacy-padding {
	.module_content {
		padding-bottom: 15px;
	}
}

.mobile-scroll-hint {
	opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
	transform: translate(-50%, -50%);
  background: #eee;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
	pointer-events: none;

  svg {
    width: 24px;
    height: 70px;
  }
}

.anchor.visible {
	.mobile-scroll-hint {
		opacity: 1;
    visibility: visible;
	}
}