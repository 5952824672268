.error_404 {
    width: 100%;
    height: 100vh;
    background: #000;
    position: relative;

    .video {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        transform: translateY(-50%);

        video {
            width: 40%;
            max-width: 40%;
        }
    }

    .error_404__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        max-width: 450px;
        text-align: center;

        h1 {
            font-size: 80px;
            font-weight: 300;
            margin-bottom: 30px;
            margin-top: 0;
        }

        p {
          font-size: 20px;

          img {
            width: 12px;
            margin: -6px 0 0 6px;
          }

          &.grey {
            padding-top: 20px;
            font-weight: 200;
            font-size: 16px;
            opacity: 0.6;
          }

          a {
            color: #fff;

            &:hover {
              text-decoration: underline;
            }

            &.button {
              display: block;
              width: 180px;
              height: 44px;
              line-height: 44px;
              border-radius: 4px;
              background: #125aff;
              color: #fff;
              font-size: 16px;
              border: none;
              padding: 0 20px;
              margin: 55px auto 20px;
              text-decoration: none;
              transition: opacity 0.2s ease-in-out;

              &:focus {
                  outline: none;
              }

              &:hover {
                  opacity: 0.8;
              }
            }
          }
        }
    }

}
