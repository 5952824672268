.brand-header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 16.6666666667%);
  height: 60px;
  z-index: 6;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  background: #fff;

  @media(max-width: 992px) {
    width: 100%;
  }

  @media(min-width: 992px) {
    &.scroll-down {
      top: -60px;
    }
  }

  @media(min-width:992px) {
    left: 0;
    width: 1px;
    height: 35px;
    background: none;
  }

  .brand-header__title {
    float: left;
    width: 300px;
    line-height: 59px;
    padding-left: 48px;
  }

  .mobile-logo {
    text-align: center;

    a {
      display: block;
      height: 60px;
    }

    img {
      max-width: 175px;
      max-height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    @media(min-width: 993px) {
      display: none;
    }
  }
}

.big-menu {
  .burger-tap {
    display: block;
  }
}

.burger-tap {
  display: none;
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 8;
  background: #fff;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 40px;
  height: 35px;
  transition: all 0.3s ease-in-out;

  @media(max-width: 992px) {
    top: 12px;
  }

  @media(max-width: 1320px) {
    display: block;
  }

  .burger {
    width: 21px;
    height: 16px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, -50%) rotate(0deg);

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #303030;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),&:nth-child(3) {
        top: 6px;
      }

      &:nth-child(4) {
        top: 12px;
      }
    }

    @media (max-width: 992px) {
      &.open {
        span {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 70%;
          }
        }
      }
    }
  }

  &.open {
    left: calc(22% + 10px);

    @media(min-width:1200px) {
      left: calc(16.66667% + 10px);
    }

    @media(min-width:1440px) {
      left: calc(240px + 10px);
    }

    @media(max-width:991px) {
      left: 10px !important;
    }

    .burger {
      &.open {
        span {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 70%;
          }
        }
      }
    }
  }
}

@media(max-width:992px) {
  .burger {
    width: 30px;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin: 15px 0 0 0;

    &:focus {
      outline: none;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #303030;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),&:nth-child(3) {
        top: 8px;
      }

      &:nth-child(4) {
        top: 16px;
      }
    }
  }
}

body.menu--hidden {
  .burger-tap {
    display: none !important;
  }

  &.menu--burger {
    .burger-tap {
      display: block !important;
    }
  }
}
