.modal--dash-backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #212121;
  transition: opacity 450ms;
  visibility: hidden;
  opacity: 0;

  &.modal--open {
    visibility: visible;
    opacity: 0.48;
  }
}

.modal--dash {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  width: 670px;
  height: 100%;
  background: #fff;
  transform: translateX(100%) scale(1)!important;
  transition: transform .4s cubic-bezier(.25,.8,.25,1);
  -webkit-box-shadow: -32px 0 87px -46px rgba(0,0,0,0.49);
  -moz-box-shadow: -32px 0 87px -46px rgba(0,0,0,0.49);
  box-shadow: -32px 0 87px -46px rgba(0,0,0,0.49);

  &.modal--open {
    transform: translateX(0) scale(1)!important;
    transition: transform .4s cubic-bezier(.25,.8,.25,1);
  }

  .dialog_back {
    top: 40px;
    left: 30px;
    z-index: 3;
    position: absolute;
    font-size: 16px;
    font-weight: 500;

    a {
      color: #16171a;
      cursor: pointer;
      transition: all .2s ease-in-out;

      svg {
        width: 20px;
        margin: 0 5px -4px 0;
      }
    }

    &:hover {
      a {
        color: #0c56ff;
      }
    }
  }

  .modal--dash__loader {
    position: fixed;
    top: 0;
    right: 0;
    width: 670px;
    z-index: 3;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 670px;
    }
  }

  .content-wrapper {
    padding: 0 0 0 55px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: 100%;

    .scroll-inner {
      height: 100%;
      overflow-y: scroll;
      padding-right: 55px;
      box-sizing: content-box;
      max-height: 100%;
      width: calc(100% - 55px);

      @media (max-width: 992px) {
        padding-left: 55px;
      }
    }

    .modal--dash__head {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      z-index: 2;
      transform: translateX(-50%);
      height: 110px;
      text-align: center;
      background: #fff;

      img {
        display: inline-block;
        width: 116px;
        margin-top: 10px;
      }

      h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #414144;
        margin-top: 42px;

        &.with-welcome {
          margin-top: 30px;
        }
      }

      span {
        display: block;
        font-size: 14px;
        margin-top: -2px;
        padding: 0 60px;
      }
    }

    .modal--dash_folders {
      background: #f9f9fa;
      margin-top: 110px;
      font-size: 14px;
      line-height: 1.6;
      font-family: 'Rubik' !important;
      font-weight: 400 !important;

      .head {
        padding: 30px 40px 30px 70px;
        font-size: 14px;
        line-height: 1;
        color: #363639;
        position: relative;
        background: url(../../../../assets/img/ico-dash-folder.svg) no-repeat;
        background-size: 18px;
        background-position: 40px 29px;
        overflow: hidden;
        cursor: pointer;

        &::after {
          content: "+";
          color: #b0b1b1;
          position: absolute;
          top: 50%;
          right: 40px;
          transform: translateY(-50%);
        }

        &.head--open {
          &::after {
            content: "-";
          }
        }

        div {
          float: left;

          &.clear {
            position: relative;
            font-size: 14px;
            color: #B0B1B1;
            margin-left: 35px;

            &::before {
              content: ".";
              position: absolute;
              left: -20px;
              top: -18px;
              font-size: 32px;
              color: #B0B1B1;
            }
          }
        }
      }

      .list {
        display: none;
        padding: 0 0 12px;

        ul {
          list-style: none;
          margin: 0 60px 0 65px;
          padding: 0;
          border-bottom: 1px solid #eeeff1;

          &.no-border {
            border: none;
          }

          li {
            color: #62626a;
            position: relative;
            cursor: pointer;
            transition: all .2s ease-in-out;

            .title {
              position: relative;
              display: block;
              padding: 9px 0;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: -9px;
              right: 0;
              z-index: 0;
              height: 38px;
              border-radius: 4px;
              background-color: #eeeef1;
              opacity: 0;
              transition: all .2s ease-in-out;
            }

            &.active {
              color: #39393c;
              font-weight: 500;

              &::before {
                opacity: 1;
              }
            }

            &.expandable {
              .arrow {
                display: block;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 7px;
                left: -28px;

                &::before {
                  content: "";
                  position: absolute;
                  top: 7px;
                  left: 8px;
                  background-image: none;
                  width: 0;
                  height: 0;
                  border-color: transparent transparent transparent #62626a;
                  border-style: solid;
                  border-width: 5px 0 5px 5px;
                  border-radius: 4px;
                }

                &.expanded {
                  &::before {
                    transform: rotate(90deg);
                  }
                }
              }
            }

            ul {
              margin: 0 0 0 30px;
              border-bottom: none;
            }
          }
        }

        &.list--open {
          display: block;
        }

        &__not {
          color: #62626a;
          margin: 10px 40px 10px 55px;
          padding: 9px 0 9px 10px;
          position: relative;
          cursor: pointer;
          transition: all .2s ease-in-out;

          &:hover {
            opacity: 0.65;
          }

          .title {
            position: relative;
            display: block;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -9px;
            right: 0;
            z-index: 0;
            height: 38px;
            border-radius: 4px;
            background-color: #eeeef1;
            opacity: 0;
            transition: all .2s ease-in-out;
          }

          &.active {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }

    .modal--dash__search {
      background: #f9f9fa;
      margin: 20px 0 35px;
      padding: 20px;

      input {
        border: none;
        padding: 8px 20px;
        font-size: 16px !important;
        color: #363639;
        width: 450px;
      }

      button {
        width: 22px;
        height: 22px;
        border: none;
        margin: 9px 10px 0 0;
        float: right;
        //background: url('../images/ico-search-assets.svg') no-repeat;
        //background-size: cover;
        opacity: 0.65;
      }
    }

    .modal--dash__assets {
      margin: 35px 0 0 0;

      &.no-folders {
        margin-top: 110px;
      }

      .asset {
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        margin-bottom: 21px;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &__preview {
          height: 159px;
          background: #eeeef1;
          border-radius: 2px;
          text-align: center;
          padding: 1px;
          overflow: hidden;
          position: relative;

          img {
            max-width: 100%;
            max-height: 156px;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .filetype {
            height: 100%;
            position: relative;

            svg {
              width: 64px;
              position: absolute;
              z-index: 1;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              fill: #d3d3df;
            }

            span {
              position: absolute;
              bottom: 50px;
              left: 40px;
              display: inline-block;
              padding: .3125rem .625rem;
              max-width: calc(100% - .625rem);
              overflow: hidden;
              text-overflow: ellipsis;
              background: #62626a;
              color: #fff;
              text-transform: uppercase;
              font-size: 9px;
              font-weight: 600;
              z-index: 2;
              border-radius: 4px;
            }
          }
        }

        &__data {
          padding: 10px 12px;

          .title {
            color: #1b1b1b;
            font-size: 15px;
            padding: 0 0 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
          }

          .details {
            color: #858585;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
          }
        }

        &:hover {
          opacity: 0.88;
        }
      }
    }

    .empty {
      width: 300px;
      margin: 0 auto;
      padding: 100px 0;

      &__icon {
        width: 96px;
        height: 96px;
        color: #62626a;
        padding: 25px;
        background-color: #eeeef1;
        border-radius: 50%;
        margin: 0 auto 30px;

        svg {
          width: 48px;
          height: 48px;
          fill: #62626a;
        }
      }

      &__title {
        color: #17171c;
        font-size: 20px;
        text-align: center;
      }
    }
  }
}

.modal--dash-image {
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;

  &.visible {
    .dash-modal-bg {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  .dash-modal-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: #000;
    opacity: 0;
    visibility: hidden;
  }

  .dash-modal-download {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    overflow: hidden;

    &__buttons {
      button {
        width: 44px;
        height: 44px;
        position: relative;
        background: 0 0;
        cursor: pointer;
        overflow: visible;
        -webkit-appearance: none;
        display: block;
        border: 0;
        padding: 0;
        margin: 0;
        float: right;
        opacity: .75;
        transition: opacity 0.2s;
        box-shadow: none;

        &:focus,
        &:active {
          outline: none;
        }

        &.download {
          background: url(../../../../assets/img/ico-download.svg) 0 0 no-repeat;
          background-position: center;
          background-size: 13px;
        }

        &.close {
          background: url(../../../../assets/img/default-skin-copy.png) 0 0 no-repeat;
          background-size: 264px 88px;
          background-position: 0 -44px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &__image {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90vw;
        max-height: 90vh;
      }
    }

    &__info {
      position: absolute;
      width: 325px;
      top: 40px;
      right: 42px;
      background: #fff;
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.4;

      &::after {
        content: "";
        height: 0;
        width: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #fff;
        border-top: 0;
        position: absolute;
        bottom: 100%;
        right: 20px;
      }

      .head {
        color: #39393c;
        font-weight: 500;
        padding: 17px 30px;
        border-bottom: 1px solid #efeef2;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        cursor: pointer;

        span {
          display: block;
          color: #62626a;
          font-size: 13px;
          font-weight: 400;
        }

        &:hover {
          background: #f9f9fa;
        }
      }

      .options {
        background: #f9f9fa;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        ul {
          list-style: none;
          margin: 0;
        }

        &__formats {
          padding: 17px 30px;

          ul {
            float: right;
            padding: 0;
            margin-right: 0;

            li {
              color: #39393c;
              display: inline-block;
              margin-left: 10px;
              position: relative;
              cursor: pointer;

              &.active::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                height: 2px;
                width: 100%;
                background: #490085;
                border-radius: 2px;
              }
            }
          }
        }

        &__sizes {
          ul {
            padding: 10px 0 20px;

            li {
              color: #39393c;
              font-weight: 500;
              padding: 12px 30px;
              cursor: pointer;

              &.with-url {
                padding: 0;
              }

              span {
                display: block;
                color: #62626a;
                font-size: 13px;
                font-weight: 400;
              }

              &:hover {
                background: #f4f4f5;
              }

              a {
                display: block;
                color: #39393c;
                padding: 12px 30px;
              }
            }
          }
        }
      }

      .modal--dash__loader {
        position: absolute;
        top: 0;
        right: 0;
        width: 325px;
        z-index: 3;
        height: 100%;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.9);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 325px;
        }
      }
    }

    &:hover {
      .dash-modal-download__buttons {
        opacity: 1;
      }
    }
  }
}