.login {
  .two-buttons {
    text-align: center;

    input[type=submit] {
      margin: 0 10px;
      display: inline-block;
    }
  }

  .form-group--counter {
    position: relative;
    margin-bottom: 30px;

    span {
      position: absolute;
      right: 5px;
      bottom: 0px;
      color: #959595;
    }
  }

  .form-error {
    min-height: 30px;
  }
}
