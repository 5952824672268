.module_video_loaded_true {
	.image {
		background-image: none;
		background-color: transparent;
	}
}

.video {
	.sound-block {
		opacity: 0;
		position: absolute;
		bottom: 10px;
		right: 10px;
		z-index: 1;
		transition: opacity .2s;
		cursor: pointer;

		.mute, .unmute {
			border-radius: 5px;
			background: #fff;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.mute {
			display: none;
		}

		svg {
			display: block;
			max-width: 15px;
		}

		.mute svg {
			max-width: 10px;
		}
	}

	&.with--sound:hover {
		.sound-block {
			opacity: 1;
		}
	}
}
.sound-block.unmuted {
	.mute {
		display: flex;
	}

	.unmute {
		display: none;
	}
}