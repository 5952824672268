.module_button {
    position: relative;
    margin: 0 auto;
    max-width: 1100px;
    height: 100%;
    font-weight: 400;

    .resizable-button {
      max-width: 100%;
    }

    .button-preview-wrapper {
      max-width: 100%;
    }

    @media (max-width: 992px) {
      .resizable-button,
      .button-preview-wrapper {
        max-width: calc(100vw - 30px);
      }
    }

    .btn-wrap {
        position: relative;
        height: auto;
        padding: 0;
        min-width: 60px;
        max-width: unset;
        border-radius: 4px;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        cursor: pointer;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-inner-wrapper {
          transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
          background: #0c56ff;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn {
          //transition: all 0.2s ease-in-out !important;
          * {
            transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out !important;
          }
        }

        &.is-hoverable:hover {
          .button-inner-wrapper {
            background-color:var(--button_hover_color) !important;
            border-color:var(--border_hover_color) !important;

            .btn {
              color: var(--text_hover_color) !important;
            }
          }

         .btn {
            * {
              color: var(--text_hover_color) !important;
            }
          }
        }

        .btn {
          line-height: unset;
          padding-top: 11px;
          padding-bottom: 11px;
          //line-height: 2.5;
          white-space: unset;

          p {
            margin: 0;
            //line-height: 2.5;
          }
          h1 {
            margin: 20px 0 0;
          }
          h1:first-child {
             margin-top: 0;
          }
          h2 {
            margin: 20px 0 0;
            color: #09163d;
          }
          h2:first-child {
            margin-top: 0;
          }
        }

        &:hover {
          .file-info {
            opacity: 1;
            visibility: visible;
          }
        }

        a {
          &:focus {
            box-shadow: none;
          }

          &:hover {
            opacity: 0.85;
          }
        }

        @media (max-width: 992px) {
          float: none;
          display: inline-block;

          .btn {
            p {
              display: inline;
            }
          }
        }
    }

    .btn {
        display: block;
        color: inherit;
        outline: none;
        border: none;
        border-radius: 0;
        white-space: pre-wrap;
        padding: 0;
        line-height: 50px;
        font-size: inherit;

        &:focus {
          outline: none;
        }

        &:active {
          outline: 0 !important;
          box-shadow: none !important;
        }
    }

    .is-activated {
        outline: none !important;
        position: relative;
        display: inline-block;
    }

    &__container {
        height: 100%;

        @media (max-width: 992px) {
          div:first-child {
            text-align: center;
            left: 0 !important;
          }
        }
    }

    .spinner-icon {
      position: absolute;
      top: 14px;
      right: 7px;
      margin: 0 !important;
    }
}
