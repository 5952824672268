.mobile-alert-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 20;
	display: none;
}

.mobile-alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: #000;
}

.mobile-alert__logo img {
	width: 160px;
	margin: 32px 0 0 24px;
}

.mobile-alert__content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    img {
	    max-width: 90%;
	    display: block;
	    margin: 0 auto;
	    max-height: 340px;
    }
}

.mobile-alert__text-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.mobile-alert__content h6 {
    margin-top: 0;
    width: 100%;
    padding: 0 35px;
    text-align: center;
    color: #fff;
    font-family: brandon-grotesque;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.2;
}

.mobile-alert__content p {
    left: 0;
    width: 100%;
    padding: 0 35px;
    text-align: center;
    color: #fff;
    font-family: brandon-grotesque;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.2;
}

.mobile-alert__input {
    width: 100%;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50px;
    max-width: 280px;
    position: absolute;
    bottom: 26%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}

.mobile-alert__input img {
    width: 19px;
    margin: 14px 0 0 19px;
}

.mobile-alert__input div {
    position: absolute;
    top: 14px;
    left: 52px;
    background: 0 0;
    border: none;
    width: 220px;
    font-family: brandon-grotesque;
    font-size: 13px;
    font-weight: 300;
    color: #fff;
    padding: 0;
    margin: 0;
}

@media only screen and (max-width: 473px) {
  .mobile-alert {
    &__content {
      h6 {
        margin-top: -55px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
	.mobile-alert-popup {
		display: block;
	}
}