
html, body {
  height: 100%;
  background: #fff;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-synthesis: none !important;
}

.no--overflow {
  overflow: hidden;
}

b, strong {
  font-weight: bold;
}

*:focus {
  outline: none;
}

a {
  color: #0c56ff;
}

a:hover, a:focus {
  text-decoration: none;
}

.container-full {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
}

.row.full-height {
  margin: 0;
  display: block;
}

.breadcrumb {
  padding: 8px 0;
}

.align-right {
  text-align: right;
}

.font-size-9px {
  font-size: 9px;
  line-height: 1.2;
}

.font-size-14px {
  font-size: 14px;
  line-height: 1.2;
}

.font-size-18px {
  font-size: 18px;
  line-height: 1.2;
}

.font-size-24px {
  font-size: 24px;
  line-height: 1.2;
}

.font-size-32px {
  font-size: 32px;
  line-height: 1.2;
}

.font-size-48px {
  font-size: 48px;
  line-height: 1.2;
}

.global-loader {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  img,
  svg {
    max-width: 350px;
  }
}

.body-loaded {
  .global-loader {
    display: none;
  }
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  display: block !important;
}
