.login.login--elon {
  color: #fff;
  font-family: Roboto;

  img {
    width: 360px;
    max-width: 60%;
    margin: 0 auto 60px;
    display: block;
  }

  h5 {
    background: none;
    padding: 0 0 30px;
    margin: 0 auto;
    font-size: 39px !important;
    width: 800px;
    max-width: 90%;
    text-align: center;

    @media (max-width: 900px) {
      width: 700px;
      font-size: 32px !important;
    }

    @media (max-width: 768px) {
      width: 90%;
      font-size: 28px !important;
    }
  }

  p {
    font-size: 18px !important;
    margin: 0 auto 42px;

    &.request {
      max-width: 285px;
      margin: 70px auto 0;
    }
  }

  .form-error {
    font-size: 16px;
    margin-bottom: 10px;
  }

  input[type=email],
  input[type=password] {
    background: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 10px 0 5px;
    font-size: 14px !important;

    &::placeholder {
      color: #fff !important;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #fff;
    }
  }

  input[type=submit] {
    width: 114px;
    height: 36px;
    line-height: 34px;
    border-radius: 0px;
    background-color: #b4a688;
    color: #221f1f;
    font-size: 18px !important;
    border: none;
    margin: 40px auto 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #221f1f;
      background-color: #b4a688;
    }
  }

  .forgot-link a {
    color: #fff;
    font-size: 14px;
  }
}

.visible--extra {
  color: blue;
}