.modal-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    background: rgba(0,0,0,0.6);
    transition: all 0.25s;

    &.visible {
        visibility: visible;
        opacity: 1;
    }
}

.modal-image {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 7;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 1000px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid #252525;
    transition: all 0.25s;

    @media(min-width: 992px) {
        min-height: 560px;
    }

    &.visible {
        visibility: visible;
        opacity: 1;
    }

    .close {
        float: right;
        margin: 20px 20px 0 0;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background: #eee;
        opacity: 1;
        transition: all 0.25s;
    }

    .copy-link {
      left: 15px;
    }

    .image {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 40px;
        height: 375px;
        position: relative;

        img {
          cursor: zoom-in;
          max-height: 375px;
          max-width: 68%;

					@media(min-width: 992px) {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
        	}

            &.svg-class {
                width: 100%;
            }
        }

        @media(max-width: 992px) {
            padding: 0 15px;
            margin-top: 45px;
        }
    }

    .modal-image_play {
        height: 220px;
        width: 220px;
        border-radius: 5px;
        border: 1px solid #b1b8c1;
        text-align: center;
        margin: 160px auto 75px;
        position: relative;

        .play {
            width: 82px;
            height: 82px;
            line-height: 80px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid #ddd;
            border-radius: 50%;
            background: #fff;

            svg#play-audio {
                width: 31px;
                height: 36px;
                margin-top: 23px;
                margin-right: 5px;
            }

            img {
                margin-top: -1px;
            }
        }
    }

    ul {
        text-align: center;
        list-style: inside;
        margin: 40px auto 20px;
        padding: 0;

        @media(max-width: 992px) {
            margin: 20px auto;
        }

        li {
            display: inline-block;
            width: 16%;
            height: 45px;
            line-height: 45px;
            border: 1px solid #e1e1e1;
            background-color: #ffffff;
            margin: 0 10px;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            position: relative;
            transition: all 0.25s;
            border-radius: 5px;
            margin-bottom: 20px;

            .attachment-item {
                span {
                    display: block;
                    width: 90px;
                    height: 23px;
                    line-height: 1.3;
                    margin-top: 12px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            a {
              color: #000;
              display: block;
              padding: 0 20px;
              transition: all 0.25s;
            }

            img.icon,
            svg.icon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                width: 18px;
                height: 18px;
                cursor: pointer;
            }

            svg {
              use {
                transition: all 0.25s;
              }
            }


            .spinner-icon.spinner-icon--20 {
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);
              width: 18px;
              height: 18px;
              margin-top: -8px;
              pointer-events: none;
            }

            @media(max-width: 992px) {
                display: block;
                width: 100%;
                max-width: 135px;
                margin: 0 auto 15px;
            }

            &:hover {
              .file-info {
                opacity: 1;
                visibility: visible;
                top: -1px;
              }
            }
        }
    }
}

.open-dam-link {
    border-radius: 32px;
    color: #fff;
    background-color: #222;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    padding: 10px;
    margin-top: 10px;
    outline: none !important;
    cursor: pointer;
}

.open-dam-link.in-preview-mode {
    margin-bottom: 20px
}

.open-dam-link span {
    font-size: 14px;
    line-height: 1
}

.open-dam-link svg {
    width: 16px;
    height: auto;
    margin-right: 8px
}

.open-dam-link-sep-border {
    margin-top: 15px;
    border-bottom: 1px solid #d1dae3!important
}

.hide-open-dam-link-sep-border {
    border: none !important;
}

.modal-image ul li.svg-class .file-info .file-info__details .preview img {
    width: 100%;
    height: auto;
}


