.showcase-header {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 62px;
  background: #000;

  .corebook-logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 17px 0 0 30px;

    @media (max-width: 1320px) {
      left: 50px;
    }

    img {
      width: 129px;
    }

    span {
      position: absolute;
      top: 25px;
      left: 167px;
      display: block;
      width: 35px;
      height: 14px;
      border-radius: 3px;
      background-color: #fff;
      text-align: center;
      color: #080808;
      font-family: 'Rubik';
      font-size: 8px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      line-height: 14px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 11px;
      right: -80px;
      width: 1px;
      height: 37px;
      background: #505050;
    }
  }

  .partner-logo {
    position: absolute;
    top: 0;
    left: 270px;
    padding: 17px 0 0;

    @media (max-width: 1320px) {
      left: 301px;
    }

    img {
      width: 101px;
    }
  }

  .try-button {
    position: absolute;
    top: 10px;
    right: 30px;

    a {
      display: block;
      width: 144px;
      height: 40px;
      border-radius: 6px;
      border: 2px solid #fff;
      color: #fff;
      font-family: "brandon-grotesque";
      font-size: 17px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 37px;
      text-align: center;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
    }
  }
}

.brand-menu-wrapper.showcase {
  padding-top: 62px;
}

.container-full.showcase {
  padding-top: 62px;

  .auth {
    display: none;
  }

  .head-image.head-image--fold {
    height: calc(100vh - 62px);
  }
}
