.module-typography {
    position: relative;
    margin: 0 auto;
    max-width: 1100px;
		font-size: 18px;

    h1 {
        margin-top: 0;
        float: none;
        color: #000;
        font-size: 32px;
        font-weight: 700;

        @media(max-width: 992px) {
            font-size: 24px;
        }

    }

    p {
        color: #16171a;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;

        @media(max-width: 992px) {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    .module-typography__font {
        text-align: center;
        padding: 70px 15px;
        color: #000;
        font-size: 64px;
        line-height: 1.2;
        word-break: break-all;

        .ng-inline-edit__input {
            text-align: center;
        }

        @media(max-width: 992px) {
            font-size: 48px;
        }
    }
}

.module-typography--embed {
    border-top: 1px solid #e1e1e1;

    pre {
      border: none;
      margin: 0;
      padding: 0;
    }

    .row {
        margin: 0 auto;
        max-width: 1130px;

        h1 {
            margin-top: 0;
            margin-bottom: 10px;
            float: none;
            color: #000;
            font-size: 32px;
            font-weight: 700;

            @media(max-width: 992px) {
                font-size: 24px;
            }

            &.css--h1 {
              margin-top: 35px;
            }
        }

        .module-typography--embed__embed {
            line-height: 27px;
            padding: 20px 64px 20px 34px;
            color: #000;
            font-family: "Roboto Mono", monospace;
            font-size: 17px;
            font-weight: 400;
            background-color: #f5f5f5;
            word-wrap: break-word;
            position: relative;
            word-break: break-all;

            .ng-inline-edit__input {
                font-family: "Roboto Mono", monospace;
                font-size: 17px;
                font-weight: 400;
                line-height: 27px;
            }

            @media(max-width: 992px) {
                font-size: 14px;
                line-height: 1.5;
                padding: 20px;
            }

            img {
              position: absolute;
              top: 22px;
              right: 25px;
              width: 22px;
              cursor: pointer;
            }
        }
    }
}

.module-typography--tester {
    @media(max-width: 992px) {
        display: none;
    }

    .row {
        margin: 0 auto;
        max-width: 1130px;

        h1 {
            margin-top: 0;
            float: none;
            color: #000;
            font-size: 32px;
            font-weight: 700;

            @media(max-width: 992px) {
                font-size: 24px;
            }
        }

        .tester-input {
            padding-bottom: 60px;

            label {
                color: #000000;
                font-size: 18px;
                font-weight: 700;
                line-height: 30px;
                display: block;
                margin-bottom: 15px;
            }

            input {
                background: #fff;
                height: 27px;
                color: #000;
                font-size: 18px;
                font-weight: 300;
                line-height: 27px;
                padding: 33px 20px;
                width: 100%;
                border: none;
            }
        }

        .tester-results {
            color: #000;

						.letters {
								font-size: 66px;
								position: absolute;
								top: 50%;
								left: 50%;
						    transform: translate(-50%,-50%);
						}

            .row {
                margin-bottom: 20px;
                background: #fff;
								position: relative;

                .col-lg-4 {
                    text-align: center;
                    font-size: 66px;
                     min-height: 360px;

                    @media(max-width: 768px) {
                        font-size: 40px;
                        height: 280px;
                        line-height: 280px;
                    }
                }

                .col-xs-8 {
									  min-height: 360px;
                    border-left: 1px solid #e1e1e1;
										padding-bottom: 40px;
										padding-left: 10%;

                    @media(max-width: 768px) {
												min-height: 280px;
                    }
                }
            }

            .tester-results__result {
                padding-top: 40px;

                h2 {
                    color: #000;
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 20px;

                    @media(max-width: 768px) {
                        font-size: 18px;
                        line-height: 1.4;
                    }
                }

                h6 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 30px;

                    @media(max-width: 768px) {
                        font-size: 15px;
                        line-height: 1.4;
                    }
                }

                .tester-results__result__text {
                    padding-bottom: 10px;
                }

                .tester-results__result__preview {
                    padding: 20px 0 0 0;
										max-width: 320px;

                    @media(max-width: 768px) {
                        padding: 15px;
                    }

                    p {
                        font-size: 18px;
                        line-height: 30px;
                        margin: 0;

                        @media(max-width: 768px) {
                            font-size: 14px;
                            line-height: 1.4;
                        }
                    }
                }
            }

            .tester-results__remove {
              background: #ff0000;
              width: 30px;
              text-align: center;
              padding: 4px;
              position: absolute;
              top: 10px;
              right: 10px;
              cursor: pointer;
            }
        }
    }
}
