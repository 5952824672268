.home {
  width: 100%;
  height: 100vh;
  background: #000001;

  .head {
    position: absolute;
    top: 45px;
    left: 62px;
    z-index: 1;

    @media(max-width: 768px){
      left: 50%;
      transform: translateX(-50%);
      margin-left: 10px;
    }
  }

  .social {
    position: absolute;
    top: 45px;
    right: 62px;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;

    li {
      display: inline-block;
      margin: 0 0 0 20px;

      img {
        width: 24px;
        height: auto;
      }
    }

    @media(max-width: 768px){
      top: 90vh;
      right: 50%;
      transform: translateX(50%);

      li {
        margin: 0 10px;
      }
    }
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;

    video {
      display: block;
      width: 100%;
    }
  }
}
