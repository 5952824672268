body.is-search-swiping {
    user-select: none;
}

body.searchOpened {
	::selection {
	    color: unset !important;
	    background: unset !important;
	}
}

.search-popup {
	.content-preview {
		display: block;
	}
}

.search-core {
	.trigger-search-popup {
		display: block;
		margin-bottom: 20px;
		margin-top: 29px;
		cursor: pointer;
		position: relative;

		span,
		svg {
			display: inline-block;
			vertical-align: middle;
		}

		.search-trigger-icon-text-box {
			position: absolute;
			left: 50%;
		    top: 50%;
		    transform: translateX(-50%) translateY(-50%);
		    transition: left .2s, transform .2s;
	        white-space: nowrap;
	        max-width: 105px;
		}

		.show-on-hover {
			display: none;
		}

		&:hover {
			.search-trigger-icon-text-box {
				left: 80px;
				width: 105px;
			}

			.search-trigger-icon-text-box.small-one {
				left: 80px;
				width: 105px;
			}
		}

		.search-trigger-icon-box {
			margin-left: 9px;
			margin-right: 12px;
			vertical-align: middle;
			position: relative;
			display: inline-flex;
			justify-content: center;
			align-items: center;

			svg {
				position: relative;
				width: 19px;
				height: auto;
				display: block;
			}
		}
	}

	.search-shorcut-box {
		position: absolute;
		right: 4px;
		transition: all .2s;
		opacity: 0;
	  line-height: 1 !important;
	  display: flex;
	  top: 50%;
    transform: translateY(-50%);
	}

	.trigger-search-popup:hover {
		.search-shorcut-box {
			opacity: 1;
		}
	}

	.trigger-search-popup .search-shorcut-quadrant {
		width: 24px;
		height: 24px;
		border-radius: 3px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		vertical-align: middle;
		border: 1px solid transparent;

		svg {
			width: 12px;
			height: auto;
		}

		&:first-child {
			margin-right: 3px;
		}

		span {
			font-family: 'Rubik' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 15px !important;
			line-height: 15px !important;
		}
	}

	.search-trigger-icon-text-box-deggree-symbol {
		line-height: 1 !important;
	}

	.trigger-search-popup span.if-windows-cmd-quadrant {
		display: none;
	}

	.js-trigger-search-popup.os-windows {
		.if-mac-cmd-quadrant {
			display: none;
		}

		.search-shorcut-quadrant.os-quadrant {
			width: 34px;
		}

		span.if-windows-cmd-quadrant  {
			display: inline-block;
		}
	}

	.fixed-layer-popup {
		width: 100%;
		height: 0%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1004;
		background: #212121;
		opacity: 0%;
		transition: opacity .2s;
	}

	.search-popup {
		width: 100%;
		width: 781px;
		position: fixed;
		z-index: 1005;
		left: calc(50vw - 274.5px);
		top: 5%;
		top: calc(50vh - 83px);
		border-radius: 6px;
		opacity: 0;
		transition: opacity .2s;
	}

	.search-popup-frame-header-title {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #FFFFFF;
		margin-left: 23px;
	}

	.search-popup-frame-header {
		height: 53px;
		background-color: #96877E;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 10;
		border-top-right-radius: 6px;
		border-top-left-radius: 6px;
	}

	.search-popup-frame-body {
		background: rgba(21, 22, 27, 0.9);
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.45);
		backdrop-filter: blur(20px);
		border-radius: 0px 0px 6px 6px;
		overflow: hidden;
		position: relative;
	}

	&.no-backdrop-filter-blur {
		.search-popup-frame-body {
			background: rgba(21, 22, 27, 1);
		}
	}

	.search-close-btn {
		margin-right: 23px;
		cursor: pointer;

		svg {
			display: block;
		}
	}

	.results-loading {
		z-index: 998;
		position: absolute;
		top: 0;
		left: 0%;
		opacity: 0;
		width: 100%;
		height: 100%;
		transition: all .4s;
	}

	.search-popup-frame-body-title {
		display: none;
	}

	.search-popup-frame-body-title span {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 19px;
		color: #FFFFFF;
	}

	.search-popup-form-heading {
		opacity: 0.5;
	}

	.search-popup-frame-wrapper {
		margin-left: 78.5px;
    margin-right: 78.5px;
		margin-top: 25px;
		margin-bottom: 43px;
	}

	.main-search-input {
		min-width: 200px;
		margin-left: -5px;
	}

	.resizing-input.main-search-box-div {
		width: 0px !important;
	}

	.resizing-input.main-search-box-div.inactive {
		opacity: 0;
		transition: all .2s;

		&:hover {
			opacity: 1;
		}
	}

	.search-popup-form-items-count-0, .search-popup-form-items-count-, .not-focused-search-input {
		.resizing-input.main-search-box-div {
			opacity: 1;
		}
	}

	.not-focused-search-input {
		.resizing-input.main-search-box-div {
			opacity: 1;
		}
	}

	.search-popup-frame-body .resizing-input span {
		pointer-events: none;
	}


}

.third-animation-step .not-focused-search-input .resizing-input.main-search-box-div {
	opacity: 0;
}

.search-result-box-loaded {
	.search-popup {
		top: calc(50vh - 260.25px);
	}
}

.search-popup-frame-body {
	input {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 19px;
		color: #FFFFFF;
		opacity: 0.5;
		background-color: transparent;
		box-shadow: none;
		border: none;
		padding: 5px;
		padding-bottom: 0;
		padding-top: 0;
		height: 30px;
	}

	.resizing-input span {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 19px;
		padding: 5px;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

.search-popup-form-bottom-border {
	border-bottom: 1px solid #fff;
	position: relative;
	height: 1px;
}

.search-popup-form-box {
	display: flex;
	position: relative;
	min-height: 30px;
	justify-content: flex-start;
	align-items: flex-start;

	svg.search-core-search-icon {
		width: 17px;
		height: auto;
		position: absolute;
		top: 7px;
		left: 0;
	}

	svg,
	input {
		display: inline-block;
		vertical-align: middle;
	}

	.resizing-input {
	    display: inline-block;
    	vertical-align: middle;
	}

	.search-tag-box {
		margin-right: 10px;
		margin-bottom: 10px;

		.resizing-input {
	    	padding-left: 14px;
	    	padding-right: 19px;

		}
	}

	margin-top: 0px;
	margin-bottom: 9px;
}

.search-popup-form-tag-clear-enter {
	position: absolute;
	right: 0;
	top: 0;
	width: 75px;
	height: 30px;
	border: 1px solid #FFFFFF;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 0.2s;
	opacity: 0;
	cursor: pointer;

	&.show-tag-hint {
		opacity: 0.5;
	}

	span {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #FFFFFF;
		margin-right: 10px;

	}

	svg {
		margin-right: 7.5px;
		margin-left: 12px;
	}
}

.search-popup-form-tag-hint-enter {
	position: absolute;
	right: 0;
	top: 0;
	width: 72px;
	height: 30px;
	border: 1px solid #FFFFFF;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 0.2s;
	opacity: 0;
	cursor: pointer;
	visibility: hidden;

	&.show-tag-hint {
		opacity: 0.5;
	}

	span {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #FFFFFF;
		margin-right: 10px;

	}

	svg {
		margin-right: 7.5px;
		margin-left: 12px;
	}
}

.hide-search-hand {
	.search-popup-hand-span {
		opacity: 0;
	}

	.search-box-append-tags {
		margin-left: 0;
	}

	svg.search-core-search-icon {
		display: none;
	}
}

.show-tag-hint {
	.search-popup-form-tag-hint-enter {
		opacity: 0.5;
		visibility: visible;
	}

	.search-core-search-icon  {
		opacity: 0;
	}

	.search-popup-form-tag-clear-enter {
		display: none;
	}
}

.search-core-search-icon {
	transition: all .2s;

	&.show-tag-hint {
		opacity: 0;
	}
}

.trigger-search-popup.js-trigger-search-popup {
	width: auto;
	margin-left: 15px;
	margin-right: 10px;
	border-radius: 3px;
	position: relative;
}

.search-box-append-tags {
	display: block;
	margin-left: 34px;
	vertical-align: middle;
	margin-right: 85px;
	overflow: hidden;
	width: 539px;
	transition: left .2s;

	.search-tag-box {
		float: left;
		position: relative;

		.search-tag-box-bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 40px;
			background-color: #fff;
		}

		input {
			position: relative;
			color: #15161B;
		}
	}

	.search-tag-box-delete {
		position: absolute;
		width: 10px;
		height: 10px;
		right: 11px;
		top: 11px;
		cursor: pointer;

		svg {
			display: block;
			margin-right: 0;
		}
	}
}

.search-popup-form {
	overflow: hidden;
	position: relative;
}

.search-popup-form-bottom-loader-bar {
	position: absolute;
	bottom: 1px;
	left: 0;
	width: 100%;
	height: 1px;
	background: rgba(255, 255, 255, 0.5);
}

.search-popup-form-loader-bar-border {
	background: linear-gradient(90deg, #244CF3 0%, #39F2BA 48.41%, #F60C83 98.2%);
	width: 0;
	height: 1px;
	transition: all 1s;
	position: absolute;
	bottom: 0px;
	left: 0;
	z-index: 9;
}

.search-popup-results-box {
	margin-top: 0;
	position: relative;
	visibility: hidden;
	height: 0;
}

.default-search-result-card {
	width: 624px;
	height: 318px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: gray;

	h1,
	h4 {
		color: #000;
		text-align: center;
		margin: 0 20px;
	}
}

.search-popup-result-carousel-loading-box-1 {
	width: 624px;
	height: 318px;
	background: #8B8E90;
	border-radius: 6px;
	position: relative;
	z-index: 9;
	overflow: hidden;
}

.search-popup-result-carousel-loading-box-2 {
	width: 624px;
	height: 318px;
	background: rgba(139, 142, 144, 0.5);
	border-radius: 6px;
	transform:  scale(1);
	position: absolute;
	top: 0;
	left: 150%;
	z-index: 8;
	transition: all .7s;
}

.search-popup-result-carousel-loading-box-3 {
	width: 624px;
	height: 318px;
	background: rgba(139, 142, 144, 0.15);
	border-radius: 6px;
	transform:  scale(1);
	position: absolute;
	top: 0;
	left: -150%;
	z-index: 7;
	transition: all .7s;
}

.search-popup-result-carousel {
	.slick-item-div.slick-slide {
		width: 624px;
		height: 318px;
	}

	&:hover {
		.search-popup-result-carousel-how-to {
			pointer-events: none;
			opacity: 0 !important;
		}
	}
}

.search-popup-result-carousel {
	position: absolute;
	top: 0;
	z-index: 999;
	opacity: 1;
	transition: all 1.8s;
	width: 624px;
	height: 318px;
	left: 0;
}

.search-popup-result-carousel.loaded {
	opacity: 1;
}

.search-popup-result-carousel-items {
	position: relative;
	top: 0;
	left: 0;
	z-index: 999;
	opacity: 0;
}
.search-popup-bottom-dots {
	opacity: 0;
}

.search-result-item-cta {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	transition: 0.2s;
	opacity: 0;
}

.search-jump-to-btn {
	background: #D9D9D9;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	border-radius: 30px;
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9;
	transform: translate(-50%, -50%);

	a {
		height: 12px;
		padding-top: 11px;
		padding-bottom: 11px;
		padding-left: 20px;
		padding-right: 20px;
		box-sizing: content-box;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	svg, span {
		display: inline-block;
		vertical-align: middle;
	}

	span {
		margin-left: 10px;
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 12px;
		text-align: center;

		color: #000000;

	}
}

.search-jump-to-btn {
	transition: all .2s;

	background-color: #0F53FF;
	span {
		color: #fff;
	}

	path {
		stroke: #fff;
	}

	span, path {
		transition: all .2s;
	}

	&:hover {
		span {
			color: #fff;
		}

		path {
			stroke: #fff;
		}
	}
}

.search-jump-to-btn{
	perspective: 1000px;
	transform-style: preserve-3d;
}

.search-jump-to-btn .svg-div {
	height: 11px;
	width: 11px;
	display: inline-block;
	transition: transform .2s;

	svg {
		display: block;
		width: 11px;
		height: 11px;
	}
}

.search-jump-to-unrelevant-btn {
	border-radius: 30px;
	padding: 11px 11px;
	background: #D9D9D9;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	margin-left: 15px;
	cursor: pointer;
	position: absolute;
	bottom: 13px;
	right: 14px;
	z-index: 9;
	transition: all .2s;
	height: 12px;
	box-sizing: content-box;

	svg {
		display: inline-block;
		vertical-align: top;
	}

	.unrelevant-hover-box {
		vertical-align: top;
		display: none;
	}

	.unrelevant-hover-box {
		width: 0;
		overflow: hidden;
		height: 12px;
		transition: all .2s;
		position: relative;
	}

	span {
		margin-left: 0;
		margin-right: 10px;
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 12px;
		text-align: center;
		color: #000000;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 141px;
		transition: all .2s;
	}

	svg path,
	svg line {
		transition: .2s all;
	}

	svg rect {
		transition: .2s all;
	}

	&:hover {
		.unrelevant-hover-box {
			width: 141px;
			display: inline-block;
		}

		background-color: #0F53FF;

		span {
			color: #fff;
		}

		svg path,
		svg line {
			stroke: #fff;
		}

		svg rect {
			fill: #0F53FF;
			stroke: #fff;
		}
	}
}

.search-relevant-text-box-wrapper {
	position: absolute;
	top: -31px;
	left: 0;
	width: 100%;
	opacity: 0;
}

.search-relevant-text-box {
	opacity: 0;
	display: none;
	padding: 5px 15px;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.15);
	border-radius: 6px;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: #FFFFFF;

	a {
		font-weight: 400;
		color: #fff;
	}
}

.search-popup-bottom-dots {
	position: absolute;
	bottom: -29px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}

.search-popup-bottom-dot {
	padding: 5px 2.5px;
	cursor: pointer;

	span {
		display: block;
		background: rgba(255, 255, 255, 0.15);
		border-radius: 40px;
		width: 8px;
		height: 8px;
		transition: all .2s;
	}

	&.active {
		span {
			background: rgba(255, 255, 255, 0.5);
			border-radius: 40px;
			width: 30px;
		}
	}
}

.final-card {
	display: block !important;
	visibility: visible !important;

	.search-popup-result-carousel-item-inner-overlay {
		display: none !important;
	}

	.search-result-item-cta {
		display: inline-flex !important;
		opacity: 1;
		width: auto;
	}
	.search-popup-result-carousel-item-inner {
		width: 624px;
		height: 318px;
		overflow: hidden;
		border-radius: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(21, 22, 27, 1);

		.inner-quadrant-1 {
			background: #333333;
			mix-blend-mode: multiply;
			opacity: 0.2;
			border-radius: 6px;
			position: absolute;
			width: 624px;
			height: 318px;
			left: 0;
			top: 0;
		}

		.inner-quadrant-2 {
			background: #333333;
			mix-blend-mode: multiply;
			opacity: 0.3;
			border-radius: 6px;
			position: absolute;
			width: 776px;
			height: 347px;
			left: 50px;
			top: 52px;
		}

		.inner-quadrant-3 {
			width: 722px;
			height: 293px;
			left: 77px;
			top: 79px;
			position: absolute;
			background: #333333;
			mix-blend-mode: multiply;
			opacity: 0.3;
			border-radius: 6px;
		}

		.inner-quadrant-4 {
			position: absolute;
			width: 674px;
			height: 239px;
			left: 101px;
			top: 106px;
			background: #333333;
			mix-blend-mode: multiply;
			opacity: 0.3;
			border-radius: 6px;
		}

		.inner-quadrant-content {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		h4 {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 300;
			font-size: 24px;
			line-height: 28px;
			text-align: center;
			color: #FFFFFF;
			margin-bottom: 30px;
		}

		h5 {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
			color: #FFFFFF;
			margin-bottom: 15px;
		}

		.brand-owner-card {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #333333;
			border-radius: 6px;
			padding: 8px 14px;
		}

		.brand-owner-card-name {
			display: block;
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 15px;
			text-align: left;
			color: #FFFFFF;
			margin-bottom: 6px;
		}

		.brand-owner-card-mail {
			display: block;
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			color: #FFFFFF;
		}

		.brand-owner-card-profile-icon-wrapper {
			border-radius: 50%;
			overflow: hidden;
			width: 40px;
			height: 40px;
			margin-right: 15px;
		}

		.brand-owner-card-profile-icon {
			width: 40px;
			height: auto;
		}

		.brand-owner-card-copy-link-icon {
			margin-left: 43px;
			cursor: pointer;
		}

		.brand-owner-card-wrapper {
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.search-result-item-cta {
		position: relative;
		left: unset;
		top: unset;
		bottom: unset;
		display: block;
	}
}

.final-card-with-different-text {
	.search-popup-result-carousel-item-inner-overlay {
		display: unset !important;
	}
}

.no-dots {
	display: none;
}

.search-popup-result-carousel-item-inner {
	position: relative;
	height: 318px;
	background-color: #fff;
	overflow: hidden;
}

.page-item-module, .masthead-item-module {
	.module_2 {
		min-height: 600px !important; /* 318px !important; */
	}
}

.search-popup-result-carousel-item {
	width: 624px;
	height: 318px;
	overflow: hidden;
	border-radius: 6px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 111;
	opacity: 0;
  user-select: none;
  visibility: hidden;

	&.active {
		z-index: 114;
		opacity: 1;
		display: block;
		visibility: visible;
		transition: none !important;

		.search-popup-result-carousel-item-inner-overlay {
			display: block;
			opacity: 0;
			transition: opacity .2s;

			.search-popup-result-carousel-item-inner-overlay-box {
				position: absolute;
				width: 627.39px;
				height: 810.46px;
			  left: -30px;
    		top: -280px;
				background: linear-gradient(180deg, #000 0%, rgba(15, 73, 255, 0) 52.6%, rgba(15, 83, 255, 0) 100%);
				border-radius: 3px;
				transform: matrix(-0.5, 0.87, -0.86, -0.5, 0, 0);
			}
		}

		&:hover {
			.search-popup-result-carousel-item-inner-overlay {
				opacity: 0.4;
			}

			.search-result-item-cta {
				opacity: 1;
			}
		}
	}

	&.current-swipeable-elem {
		.search-popup-result-carousel-item-inner-overlay {
			opacity: 0 !important;
		}

		.search-result-item-cta {
			opacity: 0 !important;
		}
	}

	.brand-content {
		width: 100% !important;
		margin-left: 0 !important;
		min-height: unset !important;
		transform: scale(.54088);
    transform-origin: top left;
    min-width: 1160px;
	}

	.btn-switch-cards {
		display: none !important;
	}

	&.second {
		left: 25px;
    transform: scale(0.95);
    z-index: 110;
    opacity: 1;
    display: block;
    transition: all .4s;
    visibility: visible;

    .search-popup-result-carousel-item-inner .brand-content {
    	transform: scale(0.5395);
    }

    .search-popup-result-carousel-item-inner-overlay {
    	position: absolute;
    	background: #767676;
    	z-index: 9;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    }

    .search-popup-result-carousel-item-inner {
    	opacity: 0;
    }
	}

	&.second-prev {
		left: -25px;
    transform: scale(0.95);
    z-index: 113;
    opacity: 1;
    display: block;
    visibility: visible;

    .search-popup-result-carousel-item-inner .brand-content {
    	transform: scale(0.5395);
    }

    .search-popup-result-carousel-item-inner-overlay {
    	position: absolute;
    	background: #767676;
    	z-index: 9;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    }

    .search-popup-result-carousel-item-inner {
    	opacity: 0;
    }
	}

	&.sneak-peak {
		.search-popup-result-carousel-item-inner-overlay {
			opacity: 0;
		}

		.search-popup-result-carousel-item-inner {
    	opacity: 1;
    }
	}

	&.third {
		left: 50px;
    transform: scale(0.89);
    z-index: 109;
    opacity: 1;
    display: block;
    visibility: visible;
    transition: all .4s;

    .search-popup-result-carousel-item-inner-overlay {
    	position: absolute;
    	background: #3E3E3E;
    	z-index: 9;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    }

    .search-popup-result-carousel-item-inner {
    	opacity: 0;
    }
	}

	&.third-prev {
		left: -50px;
    transform: scale(0.89);
    z-index: 112;
    opacity: 1;
    display: block;
    visibility: visible;

    .search-popup-result-carousel-item-inner-overlay {
    	position: absolute;
    	background: #3E3E3E;
    	z-index: 9;
    	width: 100%;
    	height: 100%;
    	top: 0;
    	left: 0;
    }

    .search-popup-result-carousel-item-inner {
    	opacity: 0;
    }
	}


	img {
		pointer-events: none;
	}

	iframe {
	    border: 0;
	    width: 624px;
		height: 318px;
	}
}

.js-search-left-swiping {
	.search-popup-result-carousel-item.second-prev {
		z-index: 106;
	}

	.search-popup-result-carousel-item.third-prev {
		z-index: 105;
	}
}

.search-popup-result-carousel-item-inner-overlay {
	position: absolute;
	top: 0;
	left: 0;
	background: transparent;
	height: 100%;
	width: 100%;
	z-index: 999;
	transition: all .2s;
}

.search-popup-result-carousel-how-to {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(28,28,28,0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all .2s;

	svg {
		margin-left: -20px;
	}

	p {
		margin-top: 25px;
	}

	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: #fff;
	text-align: center;
}

.search-popup-result-carousel-loading-box-anim {
	position: absolute;
	width: 2016px;
	height: 318px;
	left: -100%;
	top: 0;
	z-index: 10;
	opacity: 0;
	transition: left .2s;
}

.search-popup-hand-span {
	margin-right: 15px;
	position: absolute;
	top: 4px;
	font-size: 16px;
	transition: all .2s;
	display: none !important;
}

.color-type .search-key-type-info-div {
	border-radius: 100%;
	width: 22px;
	height: 22px;
	position: absolute;
	left: 6px;
	top: 5px;
	z-index: 1;
}

.search-popup-form-box .search-tag-box.color-type .resizing-input {
	padding-left: 32px;
}


// -------------------------------------------------------------------------------------------------------------------------
// Hole (namespace "hol")

$hol-ring: 8;
$hol-ring_size: 20rem;

.scene-hole{
	margin: auto;
	height: 100%;
	display: none;
}

.hol-hole{
	top: 50%;
	left: 50%;
	position: absolute;
}

.hol-a{
	display: block;
	position: absolute;
	width: $hol-ring_size;
	height: $hol-ring_size;
	margin: -$hol-ring_size/2;
	border-radius: 50%;
	opacity: 0;
	animation: hol_scale 4s infinite linear;
}

@for $i from 1 through $hol-ring{
	i:nth-child(#{$i}){
		animation-delay: $i*(4s/$hol-ring);
	}
}

@keyframes hol_scale{
	0%{
		transform: scale(2);
		opacity: 0;
		box-shadow: 0 0 50px rgba(white, 0.5);
	}

	50%{
		transform: scale(1) translate(0, -1rem);
		opacity: 1;
		box-shadow: 0 -10px 20px rgba(white, 0.5);
	}

	100%{
		transform: scale(0.1) translate(0, 1rem);
		opacity: 0;
		box-shadow: 0 50px 20px rgba(white, 0.5);
	}
}

.search-popup-result-carousel-loading-box-1 {
	left: 110%;
	transition: all .2s;
}

.first-animation-step {
	.search-popup-form-loader-bar-border {
		width: 50%;
	}
	.search-popup-frame-body-title,
	.search-core-search-icon {
		display: none;
	}
	.search-box-append-tags {
	    margin-left: 0;
	}

	.search-popup-form-box {
		margin-bottom: -1px;
	}
}

.search-popup-result-carousel-item,
.search-popup-result-carousel-item.active,
.search-popup-result-carousel-item.second,
.search-popup-result-carousel-item.third {
	left: 100%;
}

.search-popup-results-box {
	margin-top: 36.5px;
}

.search-popup-result-carousel-items {
	top: 0;
	left: 0;
}

.second-animation-step {
	.search-popup-form-loader-bar-border {
		width: 75%;
	}
	.search-popup-results-box {
		margin-top: 36.5px;
		visibility: visible;
		height: auto;
		height: 318px;
	}

	.search-popup-frame-body-title,
	.search-core-search-icon {
		display: none;
	}

	.search-box-append-tags {
		margin-left: 0;
	}

	.search-popup-result-carousel-loading-box-1 {
		left: 0%;
	}

	.search-popup-result-carousel-loading-box-anim {
		left: 0;
		opacity: 1;
	}

	.search-popup-result-carousel {
		left: 0;
		opacity: 1;
	}

	.results-loading {
		left: 0%;
		opacity: 1;
	}
}

.search-popup-result-carousel-item, .search-popup-result-carousel-item.active, .search-popup-result-carousel-item.second, .search-popup-result-carousel-item.third {
	left: 120%;
}


.loadingsearch {
	.search-popup-result-carousel-item, .search-popup-result-carousel-item.active, .search-popup-result-carousel-item.second, .search-popup-result-carousel-item.third, .search-popup-result-carousel-item.active {
		left: 120%;
		transition: left .4s !important;

		.search-popup-result-carousel-item-inner-overlay {
			opacity: 0;
		}

		.search-popup-result-carousel-item-inner {
			opacity: 1;
		}
	}

	.search-popup-result-carousel-item-intro-swipe-guide {
		transition: all .4s;
	}
}

.third-animation-step {
	.search-popup-form-loader-bar-border {
		width: 100%;
	}

	.search-popup-result-carousel {
		left: 0;
		opacity: 1;
	}

	.results-loading {
		opacity: 0;
	}
	.search-popup-result-carousel-items {
		opacity: 1;
	}

	.final-card.search-popup-result-carousel-item.not-found-2 {
		left: 0;
		opacity: 1;
	}
}

.no-results-found {
	.third-animation-step .final-card.search-popup-result-carousel-item.not-found {
		left: 0;
		opacity: 1;
	}
}

.search-popup-result-carousel-item-intro-swipe-guide {
	left: 120%;
}

.third-animation-step-a {
	.search-popup-result-carousel-item.third {
		left: 50px;
	}
}

.third-animation-step-b {
	.search-popup-result-carousel-item.second {
		left: 25px;
	}
}


.third-animation-step-c {
	.search-popup-result-carousel-item.active {
		left: 0;
	}
}

.search-relevant-text-box, .search-popup-bottom-dots {
	transition: all .4s;
}

.maintain-search-height {
	.search-popup-results-box {
	  margin-top: 36.5px;
	  visibility: visible;
	  height: auto;
	  height: 318px;
	}
	.search-popup {
	  top: calc(50vh - 260.25px);
	}
}

.third-animation-step-d {
	.search-popup-result-carousel-item-intro-swipe-guide {
		opacity: 1;
		left: 0;
	}

	.search-relevant-text-box-wrapper {
		opacity: 1;
	}

	.search-relevant-text-box.active {
		opacity: 1;
		display: block;
	}

	.search-popup-bottom-dots {
		opacity: 1;
	}
}

.fourth-animation-step {
	.search-popup-result-carousel-loading-box-3 {
		left: 0;
	}
}

.fifth-animation-step {
	.search-popup-result-carousel-loading-box-2 {
		left: 0;
	}
}

.sixth-animation-step {
	.search-popup-result-carousel, .search-popup-result-carousel-items, .search-popup-bottom-dots {
		opacity: 1;
	}

	.search-popup-result-carousel-loading {
		display: none;
	}
}

.second-animation-step-sub-step-1 {
	.search-popup-result-carousel-item.second {
		left: 25px;
		opacity: 1;
	}
}

.second-animation-step-sub-step-2 {
	.search-popup-result-carousel-item.third {
		left: 50px;
		opacity: 1;
	}

	.search-popup-result-carousel-item.second-prev {
		left: -25px;
		opacity: 1;
	}

	.search-popup-result-carousel-item.third-prev {
		left: -50px;
		opacity: 1;
	}

	.search-popup-result-carousel-item {
		left: 0;
		opacity: 0;
	}
}

.search-trigger-icon-box {
	width: 18px;
	height: 32px;
	border-radius: 3px;
}

.no-results-found {
	.search-relevant-text-box {
		display: none;
	}
}

.final-card.search-popup-result-carousel-item {
	transition: all .4s;
}

.search-popup-result-carousel-item-intro-swipe-guide {
	width: 624px;
	height: 318px;
	position: absolute;
	top: 0;
	left: 120%;
	display: block;
	z-index: 9999;
	border-radius: 6px;
	opacity: 0;
	overflow: hidden;

	.search-popup-result-carousel-item-intro-swipe-guide-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.8;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #fff;
	}
}

.howtoguideopened .search-popup-result-carousel-item.active .search-popup-result-carousel-item-inner {
	filter: blur(5px);
}

.search-popup-result-carousel-item-intro-swipe-guide-overlay-bg {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #414141;
	opacity: 0.7;
}

.search-relevant-text-box {
	opacity: 0;
}

.sixth-animation-step {
	.search-popup-result-carousel-item-intro-swipe-guide {
		opacity: 1;
	}

	.search-relevant-text-box {
		opacity: 1;
	}
}

.no-results-found {
	.search-popup-result-carousel-item-intro-swipe-guide  {
		display: none;
	}
}

.final-card.search-popup-result-carousel-item-popup {
	width: 624px;
	height: 318px;
	overflow: hidden;
	border-radius: 6px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99999;
	opacity: 0;
	display: none !important;
}

.unrelevant-view-called {
	.final-card.search-popup-result-carousel-item-popup {
		opacity: 1;
		display: block !important;
	}
}

.flashback {
	.search-popup-result-carousel-item-inner-overlay {
		opacity: 0 !important;
	}

	.search-popup-result-carousel-item-inner {
		opacity: 1 !important;
	}
}

.js-search-core.search-core {
	position: relative;
	pointer-events: none;
}

.js-search-core.show-search-popup .search-popup {
	opacity: 1;
}
.js-search-core.show-search-popup .fixed-layer-popup {
	display: block;
	opacity: .42;
	height: 100%
}
.js-search-core.show-search-popup {
	pointer-events: unset;
}

.search-relevant-text-box {
	transition: all .1s;
	transform-origin: left center;
}

.search-relevant-text-box-wrapper.last-one-result {
	opacity: 0;
}

.search-relevant-text-box.animating-result {
	top: -35px;
	transform: scale(1.05);
	opacity: 0;
}

.search-relevant-text-box {
	top: 10px;
	transition: all .3s;
	transform: scale(1.05);
	opacity: 0;
	display: block;
	z-index: 1;
}

.search-relevant-text-box.fadeOut.active {
	display: block;
	top: -10px;
	transform: scale(1.05);
	opacity: 0;
}

.search-relevant-text-box.fadeIn {
	display: block;
	top: 0px;
	transform: scale(1);
	opacity: 1;
}

.search-relevant-text-box.active {
	top: 0px;
	transform: scale(1);
	opacity: 1;
	z-index: 2;
}


.search-relevant-text-box.animating-result-step-2 {
	top: -15px;
	transform: scale(1);
	opacity: 0;
}

.resizing-input.js-resizing-input.main-search-box-div {
	float: left;
}

.search-popup-result-carousel-items {
	height: 100%;
}

.loader-ring-div-1 {
	position: absolute; top: 0%; opacity: 1; left: 0; width: 100%; height: 100%
}

.loader-ring-div-2 {
	color: #3f3f3f; width: 300px; height: 80px; top: 50%; margin-top: -40px; left: 50%; margin-left: -150px; position: absolute;
}

.rotating-loading-ring img {
	display: block; margin: 0 auto; width: 60px; height: 60px;
}

 @-webkit-keyframes rotating {
      0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @-moz-keyframes rotating {
      0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @-o-keyframes rotating {
      0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @-ms-keyframes rotating {
      0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
      100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
    }
    @keyframes rotating {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .rotating-loading-ring {
      -webkit-animation: rotating 4.5s linear infinite;
      -moz-animation: rotating 4.5s linear infinite;
      -ms-animation: rotating 4.5s linear infinite;
      -o-animation: rotating 4.5s linear infinite;
      animation: rotating 4.5s linear infinite;
    }