.module--spacing.module--nopadding {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.module-spacing {
    position: relative;
    margin: 0 auto;
    max-width: 1070px;

    div {
      width: 100%;
    }

    .module-spacing__line {
        border-bottom: 1px solid #e6ecec;
    }
}
