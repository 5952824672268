@font-face {
  font-family: 'Agrandir-Medium';
  src:  url('/assets/fonts/agrandir/Agrandir-HelloFresh_Medium.woff2') format('woff2'),
        url('/assets/fonts/agrandir/Agrandir-HelloFresh_Medium.woff') format('woff');
}

@font-face {
  font-family: 'Agrandir-Regular';
  src:  url('/assets/fonts/agrandir/Agrandir-HelloFresh_Regular.woff2') format('woff2'),
        url('/assets/fonts/agrandir/Agrandir-HelloFresh_Regular.woff') format('woff');
}

$headerHeight: 65px;

.static-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $headerHeight;
	z-index: 10;
	background-color: #fff;
	transition: all 0.3s ease-in-out;
	filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));

	&__logo {
		img {
			width: 21px;
			margin-top: 19px;
    	margin-left: 30px;
		}
	}

	&__brands {
		position: absolute;
		left: 105px;
		top: 0;
		margin-top: 19px;

		@media (max-width: 500px) {
			left: 75px;
		}

		.trigger-dropdown {
			font-size: 20px;
			font-family: 'Agrandir-Medium';
			color: #000;
			font-weight: normal;
			cursor: pointer;

			svg {
				margin-bottom: -4px;
				margin-right: 5px;
				transition: all 0.2s ease-in-out;
			}

			.dropdown-brands {
				display: none;
				background-color: #fff;
				padding: 30px 0;
				margin-top: 24px;
				margin-left: -26px;
				border-radius: 4px;
				position: relative;
				visibility: hidden;
				opacity: 0;
				transition: all 0.2s ease-in-out;
				-webkit-filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
    		filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));

				&::after,
				&::before {
					bottom: 100%;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
				}

				&::after {
					border-color: rgba(136,183,213,0);
					border-bottom-color: #fff;
					border-width: 6px;
					margin-left: -5px;
				}

				ul {
					list-style: none;
					padding: 0;
					margin: 0;

					li {
						a {
							display: block;
							color: #000;
							font-size: 16px;
							font-family: 'Agrandir-Regular';
							padding: 6px 30px;
							text-align: left;
							transition: all 0.2s ease-in-out;

							&.active {
								font-family: 'Agrandir-Medium';
							}

							&:hover {
								background-color: #ccc;
							}
						}
					}
				}
			}

			&:hover {
				svg {
					transform: rotate(180deg);
				}

				.dropdown-brands {
					display: block;
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}

body.with--static-header {
	padding-top: $headerHeight;
	transition: padding 0.3s ease-in-out;

	.brand-header {
		top: $headerHeight;
		transition: all 0.3s ease-in-out;
	}

	.burger-tap {
		top: $headerHeight;
		transition: all 0.3s ease-in-out;
	}

	@media (max-width: 992px) {
		.burger-tap {
			top: 77px !important;
			transition: all 0.3s ease-in-out;
		}
	}

	.brand-menu {
		top: $headerHeight;
		transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;

		@media (max-width: 992px) {
			top: 125px !important;
		}
	}
}