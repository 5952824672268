.module_audio {
    position: relative;
    margin: 0 auto;
    max-width: 1100px;

    .height_100 {
      height: 100%;
    }

    .relative_pos {
      position: relative;
      overflow: hidden;
    }

    &__title {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      padding-bottom: 10px;
      min-width: 90px;
      min-height: 40px;
      cursor: initial;
    }

    &__audio {
        float: right;
        height: 220px;
        width: 220px;
        border-radius: 5px;
        border: 1px dashed #b1b8c1;
        position: relative;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s;

        @media(max-width: 768px) {
            float: none;
        }

        &.focus {
            border: 1px solid #0c56ff;
        }
    }

    &__play {
        height: 220px;
        width: 220px;
        max-width: 100%;
        border-radius: 5px;
        border: 1px solid #b1b8c1;
        position: relative;
        text-align: center;
        background-size: cover;
        background-position: center;
        
        @media(max-width: 768px) {
            float: none;
            width: 100%;
        }

        .ico-audio-play {
            width: 82px;
            height: 82px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -41px;
            margin-top: -41px;
            cursor: pointer;
            border: 1px solid #dddddd;
            border-radius: 50%;

            span {
                display: block;
                width: 64px;
                height: 64px;
                background-color: #0c56ff;
                border-radius: 50%;
                margin: 8px 0 0 8px;
                position: relative;
                transition: all 0.2s;

                img {
                    width: 18px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin-left: 2px;
                }
            }

            &:hover {
                span {
                    opacity: 0.85;
                }
            }

            &.play--pause {
                span {
                    img {
                        margin-left: 0;
                        width: 14px;
                    }
                }
            }
        }
    }

    &__download {
      position: absolute;
      right: 15px;
      bottom: 15px;
      background: #eee;
      width: 32px;
      border-radius: 32px;
      text-align: center;
      height: 32px;
      line-height: 30px;
      cursor: pointer;

      button {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;

        &:focus, &:active {
          outline: none;
        }
      }

      svg {
        width: 14px;
        margin-top: 5px;
      }
    }
}
